<div class="fullsize-area login_blk"
  style='background: url("{{bodyBgImg}}") 100% center / cover no-repeat; background-position:100%; background-size:cover;'>
  <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="login-box">
      <div class="fullsize-area">
        <div class="login-box-header">
          <img src="assets/images/ArcadiaOneLogo.svg" class="login-logo1" />
            </div>
        <div class="login">
          <mat-card-header>
            <mat-card-title>Reset Password</mat-card-title>
          </mat-card-header>
          <form class="unlock-form example-form" [formGroup]="updatePassword" (ngSubmit)="onUpdatePasswordSubmit()"
            autocomplete="off">
            <ngx-spinner name="loginLoader" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>

            <mat-card-content>
              <mat-form-field appearance="outline" class="example-full-width password_icon">
                <mat-label style="margin-left:40px; font-weight:400;">New Password</mat-label>
                <div class="d-flex">
                  <input style="padding-left:40px" matInput class="login-textbox" type="password" placeholder="Password"
                  [ngClass]="{ 'is-invalid': (isUpdatePasswordSubmitted || updatePasswordControl.password.dirty) && updatePasswordControl.password.errors }"
                  formControlName="password" maxlength="30" minlength="12" required (keydown)="onKeyDown($event)"
                  [type]="hide ? 'password' : 'text'">
                  <span class="eye_icon" (click)="myPasswordFunction1()" [ngClass]="hide ? 'fa fa-eye' : 'fa fa-eye-slash'">
                  </span>
                  <mat-error
                  *ngIf="(isUpdatePasswordSubmitted || updatePasswordControl.password.dirty) && updatePasswordControl.password.errors"
                  class="error">
                  <span *ngIf="updatePasswordControl.password.errors.required">{{
                  validationMessage.common.requireError }}</span>
                </mat-error>
                </div>
              </mat-form-field>
            
            <mat-form-field appearance="outline" class="example-full-width password_icon">
                <mat-label style="margin-left:40px; font-weight:400;">Confirm Password</mat-label>
                <div class="d-flex">
                  <input style="padding-left:40px" matInput  class="login-textbox" type="password" placeholder="Confirm Password"
                  [ngClass]="{ 'is-invalid': (isUpdatePasswordSubmitted || updatePasswordControl.c_password.dirty) && updatePasswordControl.c_password.errors }"
                  formControlName="c_password" maxlength="30" minlength="12" required (keydown)="onKeyDown($event)"
                  [type]="hide1 ? 'password' : 'text'">
                  <span class="eye_icon" (click)="myPasswordFunction2()" [ngClass]="hide1 ? 'fa fa-eye' : 'fa fa-eye-slash'">
                  </span>
                  <mat-error
                  *ngIf="(isUpdatePasswordSubmitted || updatePasswordControl.c_password.dirty) && updatePasswordControl.c_password.errors"
                  class="error">
                  <span *ngIf="updatePasswordControl.c_password.errors.required">{{
                    validationMessage.common.requireError }}</span>
                  <span *ngIf="updatePasswordControl.c_password.errors.mustMatch">{{
                    validationMessage.login.passwordmatchErr }}</span>
                </mat-error>
                </div>

            </mat-form-field> 
            </mat-card-content> 
            <button mat-raised-button size="medium" color="primary" class="submit-button mb-3" [disabled]="btnSpinner"><i class="fa fa-spinner fa-spin" *ngIf="btnSpinner"></i><i class="fa fa-unlock fa-lg fa-fw" *ngIf="!btnSpinner"></i> Reset Password </button>
          </form>
        </div>
       
        <div class="login-note text-left">
          <p class="text-left pl-2 pt-2"><strong> Password Policy </strong></p>
          <ul>
            <li><b>Length: </b>Use a password with 12 to 30 characters.</li>
            <li><b>Complexity: </b>Combine uppercase and lowercase letters, numbers, and special symbols like $, @, !, *, &, #.</li>
            <li><b>Password Reuse: </b>Avoid reusing any of your last five passwords.</li>
            <li><b>Prohibited Passwords:</b> Avoid common passwords like "Arcadiaone1!", "Password111!", and similar.</li>
          </ul>
      </div>
      </div>
    </div>

    <div class="fotBlk">
      <img src="assets/images/ArcadiaOneLogoWhite.svg" class="loginLogoFot" />
    </div>
  </div>
</div>
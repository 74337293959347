<!-- Left Menu -->
<div class="left-menu" *ngIf="showSideMenu">
    <div class="me-3" >
        <button class="navbar-toggler navbar-toggler align-self-center" type="button"
            data-bs-toggle="minimize">
            <i class="fas fa-arrow-left" (click)="toggleClassOnBody()"></i>
            <i class="fas fa-arrow-right" (click)="toggleClassOnBody()"></i>
        </button>
    </div>
    <div *ngIf="sideMenu[0].menuSectionName!='Receipt Info' && sideMenu[0].menuSectionName!='Shipment Info'">
        <a [ngClass]="{'active-left-menu two-lines': slug.includes('/'+menuItem.reportURL.substring( menuItem.reportURL.lastIndexOf('/') + 1).trim()) ,  'two-lines': !slug.includes(menuItem.reportURL.substring( menuItem.reportURL.lastIndexOf('/') + 1).trim()) }"
        *ngFor="let menuItem of sideMenu[0].reportMenu; let i = index" routerLink="/{{menuItem.reportURL}}">
        <i class="{{menuItem.reportImageName}}"></i>
        <div class="linkTxt">{{ menuItem.reporttitlename }}</div>
    </a>
    </div>
    <div *ngIf="sideMenu[0].menuSectionName==='Receipt Info'">
        <div *ngIf="groupedReportMenu().completedTransactions.length > 0">
            <div class="header-text sideMenu-header"><strong><b class="header-text-spacing">Completed Transactions</b></strong></div>
            <a [ngClass]="{'active-left-menu two-lines': slug.includes('/' + menuItem.reportURL.substring(menuItem.reportURL.lastIndexOf('/') + 1).trim()), 'two-lines': !slug.includes(menuItem.reportURL.substring(menuItem.reportURL.lastIndexOf('/') + 1).trim()) ,'header-text-spacing': sideMenuToggle }"
               *ngFor="let menuItem of groupedReportMenu().completedTransactions" routerLink="/{{menuItem.reportURL}}"
               >
                <i class="{{menuItem.reportImageName}}"></i>
                <div class="linkTxtReceipts">{{ menuItem.reporttitlename }}</div>
            </a>
        </div>
        
        <div *ngIf="groupedReportMenu().openTransactions.length > 0">
            <div class="header-text mt-4 sideMenu-header"><strong><b class="header-text-spacing">Open Transactions</b></strong></div>
            <a [ngClass]="{'active-left-menu two-lines': slug.includes('/' + menuItem.reportURL.substring(menuItem.reportURL.lastIndexOf('/') + 1).trim()), 'two-lines': !slug.includes(menuItem.reportURL.substring(menuItem.reportURL.lastIndexOf('/') + 1).trim()) ,'header-text-spacing': sideMenuToggle}"
               *ngFor="let menuItem of groupedReportMenu().openTransactions" routerLink="/{{menuItem.reportURL}}">
                <i class="{{menuItem.reportImageName}}"></i>
                <div class="linkTxtReceipts">{{ menuItem.reporttitlename }}</div>
            </a>
        </div>
    </div>
    <div *ngIf="sideMenu[0].menuSectionName==='Shipment Info'">
        <div *ngIf="groupedReportMenu().completedTransactionsShipmentInfo.length > 0">
            <div class="header-text sideMenu-header"><strong><b class="header-text-spacing">Completed Transactions</b></strong></div>
            <a [ngClass]="{'active-left-menu two-lines': slug.includes('/' + menuItem.reportURL.substring(menuItem.reportURL.lastIndexOf('/') + 1).trim()), 'two-lines': !slug.includes(menuItem.reportURL.substring(menuItem.reportURL.lastIndexOf('/') + 1).trim()) ,'header-text-spacing': sideMenuToggle }"
               *ngFor="let menuItem of groupedReportMenu().completedTransactionsShipmentInfo" routerLink="/{{menuItem.reportURL}}"
               >
                <i class="{{menuItem.reportImageName}}"></i>
                <div class="linkTxtReceipts">{{ menuItem.reporttitlename }}</div>
            </a>
        </div>
        
        <div *ngIf="groupedReportMenu().openTransactionsShipmentInfo.length > 0">
            <div class="header-text mt-4 sideMenu-header"><strong><b class="header-text-spacing">Open Transactions</b></strong></div>
            <a [ngClass]="{'active-left-menu two-lines': slug.includes('/' + menuItem.reportURL.substring(menuItem.reportURL.lastIndexOf('/') + 1).trim()), 'two-lines': !slug.includes(menuItem.reportURL.substring(menuItem.reportURL.lastIndexOf('/') + 1).trim()) ,'header-text-spacing': sideMenuToggle}"
               *ngFor="let menuItem of groupedReportMenu().openTransactionsShipmentInfo" routerLink="/{{menuItem.reportURL}}">
                <i class="{{menuItem.reportImageName}}"></i>
                <div class="linkTxtReceipts">{{ menuItem.reporttitlename }}</div>
            </a>
        </div>
    </div>
    
    
    
</div>
<!-- Left Menu -->
import { Component, ElementRef, EventEmitter, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { PopupService } from '@ng-bootstrap/ng-bootstrap/util/popup';

@Component({
  selector: 'field-builder',
  template: `
  <div class="form-group" [formGroup]="form">
  <div class='d-flex justify-content-left'>
  <label [attr.for]="field.filterLabel"><h5><span *ngIf="field.filterType !== 'checkbox'"> {{field.filterLabel}}</span>
  <strong class="text-danger" *ngIf="field.isRequired && form.get(field.filterCode).invalid">*</strong></h5>
</label>
<span style="margin-top:4px" *ngIf="field?.tooltipInformation && field.filterType !== 'file' && field.filterType !== 'checkbox' ">&nbsp;<i class="fa fa-info-circle" title="Click here" #toggleButton (click)="toggleMenu()"></i></span>

  </div>
   

    <div [ngSwitch]="field.filterType" [ngClass]="{'checkboxBlk': field.filterType == 'checkbox'}">
      <textbox *ngSwitchCase="'textbox'" [field]="field" [form]="form.get(field.filterCode)"></textbox>
      <datepicker *ngSwitchCase="'singledate'" [field]="field" [form]="form.get(field.filterCode)"></datepicker>
      <dropdown  *ngSwitchCase="'dropdown'" [field]="field" [form]="form.get(field.filterCode)" (onChange)="callSomeFunction($event)"></dropdown>
      <div class="d-flex" style="gap:5px">
      <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form.get(field.filterCode)"></checkbox>
      <span style="margin-top:24px" *ngIf="field.filterType == 'checkbox' && field?.tooltipInformation"><i class="fa fa-info-circle" title="Click here" #toggleButton (click)="toggleMenu()"></i></span>
      </div>
      
      <radio *ngSwitchCase="'radio'" [field]="field" [form]="form.get(field.filterCode)"></radio>
      <file *ngSwitchCase="'file'" [field]="field" [form]="form.get(field.filterCode)"></file>
      <!-- <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty">{{field.label}} is required</div> -->
    </div>
  </div>

  <!-- ===Tooltip Information Start=== -->
  <ng-container class="menu" *ngIf="isMenuOpen" #menu>
  <div class="popupFullBlk" style="left:15px;">
      <div class="modal-header helpBg">
          <h6 class="modal-title">Information</h6>
          <button type="button" class="close" aria-label="Close" (click)="closePopUp()"
              >
              <span aria-hidden="true">×</span>
          </button>
      </div>
      <div class="modal-body">
          <div class="row">
              <div class="col-md-12">
                  
                  <p [innerHTML]="field?.tooltipInformation"></p>
              </div>
          </div>
      </div>
  </div>
</ng-container>
<!-- ===Tooltip Information End=== -->
  `
})
export class FieldBuilderComponent implements OnInit {
  
  @Input() field:any;
  @Input() form:any;
  isMenuOpen = false;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu;

  // accountCodeHelpShow: boolean = false;
  get isValid() { return this.form.controls[this.field.filterCode].valid; }
  get isDirty() { return this.form.controls[this.field.filterCode].dirty; }

  @Output() onChange = new EventEmitter<any>();

  constructor(private renderer: Renderer2,private el : ElementRef) {
   
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  ngOnInit() {
    this.renderer.listen('window', 'click', (e: any) => {

      if (e.target.className!='')  {
        const isClickInside = this.el.nativeElement.contains(event.target);
        if(!isClickInside){
          this.closePopUp()
        }
        
        if(!e.target.className.includes('fa') && !e.target.className.includes('form-check-input')){
          this.isMenuOpen = false;
        }
        
        
      } 
    });
   } 
   closePopUp(){
    this.isMenuOpen=false
   }

  callSomeFunction($event){  
    this.onChange.emit($event);
  }

}

import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ApiserviceService } from "src/app/services/apiservice.service";
import { APIConstant } from 'src/app/constants/apiConstants';
import { validationMessage } from "src/app/constants/validationMessage";
import { NgxSpinnerService } from "ngx-spinner";
import * as _ from 'lodash'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { navigationConstants } from 'src/app/constants/navigationConstant';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { log } from 'console';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.css']
})

export class ReportFiltersComponent implements OnInit {
  public form: FormGroup | any;
  emailReportForm: FormGroup;
  unsubcribe: any
  userData: any = []
  reportId: string = null
  defaultDateRange: string
  defaultSrchDateFrom: string
  defaultSrchDateTo: string
  validationMessage = validationMessage;
  showDateControls: boolean = false;
  SrchDateFrom: Date;
  SrchDateTo: Date;
  isFormControlsReady: boolean = false
  dateControlsDisabled: boolean = true
  reportTitle: string = ''
  showScheduleButton: boolean = false;
  reportTableHeader: any = [];
  reportTableHeaderBackup: any = [];
  reportTableHeaderExport: any = [];
  reportTableData: any = [];
  reportTableDataBackup: any = [];
  reportTableDataExport: any = [];
  reportExcludeColumns: any = [];
  noDataFlag: boolean = false;
  popupColumnsArr: any = [];
  drillDownColumnsArr: any = [];
  accountCodeHelpShow: boolean = false;
  closeResult: string;
  modelText: string = '';
  modelHeading: string = '';
  selectedSearchParams: any = '';
  selectedAccounts: any = [];
  currentPopupField: string = ''
  listOfFiles: any = [];
  selectedRows: any[] = [];
  fileList: File[] = [];
  deleteFile: any = [];
  isLoading = false;
  allowedUploadFileType: any = ['pdf', 'docx', 'doc', 'png', 'xlsx', 'txt']
  responseData: any[] = [];
  responseDataDisplay: any = [];
  downloadDisplay: any = [];
  ondeleteupload: boolean = false;
  uploadModal: any = '';
  downloadModal: any = '';
  isdeleted: boolean = false;
  scheduleid: number = 0;
  isEdit = false;
  selectDateRange: any = null
  downloadField:boolean=false;
  docDownloadField:boolean=false;
  currentUrl:any;
  isHightlightVoid!:boolean;
  VoidHeading:any[]=[];
  title:any;

  

  public group: any = {
    name: "search",
    fields: []
  }
  timeRange: any = [
    { id: 'today', label: 'Today' },
    { id: 'yesterday', label: 'Yesterday' },
    { id: 'thisweek', label: 'This week' },
    { id: 'lastweek', label: 'Last week' },
    { id: 'thismonth', label: 'This Month' },
    { id: 'lastmonth', label: 'Last Month' },
    { id: 'custom', label: 'Custom' }
  ];
  siteOptions: any = [];
  allUserEmails: any = [];
  selectedEmailCounter: number = 0
  openSkyInvoices: any = ['INVCR023.00', 'INVCR024.00']
  isMainReport: boolean = true
  isDrillDownReport: boolean = false
  backPayload: any = []
  anyItemSelect: boolean = false
  drillDownTitle: string = ''
  drillDownPayload: string = ''
  drillDownProcedure: string = ''
  drillDownfilterOptions: any = []
  isRegenerate: boolean = false;


  page: number = 1
  itemsPerPage: 5
  pageBackup: number = 1
  itemsPerPageBackup: 5
  totalItems: any
  isExportingReport: boolean = false
  exportType: string = ''
  signatureImg: string = ''
  iframePdfUrl: string = ''
  isDateRangeVisible:boolean=false

  drivername: string = '';
  drivernamerequired: boolean = false;
  reflectError: boolean = false
  imgArr:any[]=[]
  isMaximized: boolean = true;
  currentIndex = 0;
  nextSlideArrowDisable:boolean=false

  isZoomed: boolean = false; // Updated to match the number of images
  pos = { top: 0, left: 0, x: 0, y: 0 };

  
  @ViewChild('container') 'container': ElementRef;
  @ViewChild('image') 'image': ElementRef;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: { items: 1 },
      400: { items: 2 },
      760: { items: 3 },
      1000: { items: 4 },
    },
    nav: true,
  };


  @ViewChild('linksPopup') linksPopup: any;
  @ViewChild('EmailReportPopup') EmailReportPopup: any;
  @ViewChild('deleteconfirmation') deleteconfirmation: any;
  @ViewChild('mediaPopUp') mediaPopUp: any;


  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private apiserviceService: ApiserviceService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private confirmDialogService: ConfirmDialogService,
  ) {
    let token = this.commonService.getToken();
    if (token == null || token == undefined || token == '') {
      //this.commonService.notification(validationMessage.toasterClass.error, validationMessage.common.tokenInvalid);
      this.commonService.clearSession();
      this.commonService.redirectToPage(navigationConstants.LOGIN);
    }
    this.userData = JSON.parse(localStorage.getItem('ArcUser'));
    this.selectedAccounts = this.userData.allAccounts.filter(item => item.checked == true).map(i => i.cust_id);
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.userData = JSON.parse(localStorage.getItem('ArcUser'));
        const trimUrl = router.url.slice(1).split('/');
        const trimedUrlLength = trimUrl.length
        if (trimedUrlLength === 3) {
          const currentUrl = this.router.url;
          const urlParts = currentUrl.split('/');
          const lastPart = urlParts[urlParts.length - 1];
          const decryptedValue = this.commonService.decrypt(lastPart);
          this.scheduleid = decryptedValue;
          
        }
        trimUrl.pop()
        const trimedUrl = trimUrl.join('/')

        const pageUrl = trimedUrlLength === 3 ? trimedUrl : router.url.slice(1);
        
        if (pageUrl && this.userData) {
          let urlSegments = router.url.slice(1).split('/');

          const reportArr = this.userData.menu?.filter((x) => {
            return x.menuURL == urlSegments[0] && x.reportMenu != null
          }).map((y) => {
            return y.reportMenu.filter(z => z.reportURL === pageUrl)
          })[0];


          if (reportArr && reportArr.length) {
            this.reportId = reportArr[0].reportid
            this.reportTitle = reportArr[0].reporttitlename
            this.showScheduleButton = reportArr[0].reportallowschedule;
            
          }
        }
      }
    });
  }


  ngOnInit() {
    const currentUrl = this.router.url;
    const urlParts = currentUrl.slice(1).split('/');
    // const lastPart = urlParts[urlParts.length - 1];
    
    this.dateRangeMandatory()
    
    
    
    this.form = new FormGroup({});
    if (urlParts.length === 3) {
      this.getAndEditReportFilters();
      
      this.isEdit = true;
    } else {
      this.getAndSetReportFilters()
    }

    var resArr = [];
    this.userData.allAccounts.filter((item) => {
      var i = resArr.findIndex(x => (x.whse_code == item.whse_code));
      if (i <= -1 && item.checked) {
        resArr.push(item);
      }
      return null;
    });

    resArr.map((x) => {
      this.siteOptions.push({
        dropDownValue: x.whse_code,
        dropDownText: x.wh_name
      })
    })
    this.emailReportForm = this.formBuilder.group({
      emails: [null, [Validators.required]],
      comment: ['', []]
    });
    
    
  }

  onFileChanged(event: any) {
    const selectedFiles = event.target.files;

    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];

      if ((selectedFile.size / 1024) / 1024 > 2) {
        this.commonService.notification(this.validationMessage.toasterClass.error, `File size can't be more than 2 MB`);
        return false;
      }

      if (this.responseData.some(item => item.fileName === selectedFile.name)) {
        this.commonService.notification(this.validationMessage.toasterClass.error, 'Duplicate File Not Allowed');
        event.target.value = '';
        return false;
      }

      if (this.fileList.some(file => file.name === selectedFile.name)) {
        this.commonService.notification(this.validationMessage.toasterClass.error, 'Duplicate File Not Allowed');
        event.target.value = '';
        return false;
      }
      this.fileList.push(selectedFile);
      this.listOfFiles.push({ name: selectedFile.name, size: selectedFile.size / 1024 / 1024 });
    }
  }


  removeSelectedFile(index) {
    this.deleteFile.push(this.listOfFiles[index].name)
    this.listOfFiles.splice(index, 1);
    this.fileList.splice(index, 1);
  }

  

  maximize() {
    this.isMaximized = !this.isMaximized;
    if(this.isMaximized){
      this.modalService.dismissAll()
      this.openlinksPopupMaxModel(this.linksPopup)
    }
    else{
      this.modalService.dismissAll()
    this.openlinksPopupModel(this.linksPopup)
    }
    
  }
  modalDismiss(){
    this.isMaximized=true
    this.modalService.dismissAll()
  }
  

 

  nextSlide() {
    this.spinner.show('dataTableLoader');
    this.isLoading = true; 
    if (this.currentIndex < this.imgArr.length - 1) {
        this.currentIndex++;
    }
    
  }

  prevSlide() {
    this.spinner.show('dataTableLoader');
    this.isLoading = true; 
    if (this.currentIndex > 0) {
        this.currentIndex--;
    }
    
  }

  onImageLoad() {
    this.spinner.hide('dataTableLoader'); // Hide the spinner
    this.isLoading = false; // Show the column again after image is loaded
  }

  isNextSlideDisabled(): boolean {
    return this.currentIndex >= this.imgArr.length - 1; // Disable if at the last image
  }



  openUploadPopup(content?: any) {
    if (!this.ondeleteupload) {
      this.ondeleteupload = true
      this.uploadModal = content
    }
    if (this.selectedRows.length === 0) {
      this.commonService.notification(this.validationMessage.toasterClass.warning, 'Select the order(s) first.');
      return;
    }
    else {
      this.modalService.open(this.uploadModal, {
        size: "xl modal-dialog-centered",
        backdrop: 'static',
        windowClass: "modalClass-700",
        keyboard: false,
        ariaLabelledBy: "modal-basic-title"
      });
      let SiteID: any = [];
      let ClientID: any = [];
      let OrderNumber: any = [];
      const currentRoute = this.router.url;

      if (currentRoute == '/receipts/inbound-documents') {
        this.selectedRows.forEach((item) => {
          SiteID.push(item.Warehouse)
          ClientID.push(item['Client ID'])
          OrderNumber.push(item.Warehouse + '~' + item['Client ID'] + '~' + item['Warehouse Order No.'])
        })
      }
      else if (currentRoute == '/shipment-info/outbound-documents') {
        this.selectedRows.forEach((item) => {
          SiteID.push(item.Warehouse)
          ClientID.push(item['Client ID'])
          OrderNumber.push(item.Warehouse + '~' + item['Client ID'] + '~' + item['Warehouse Order#'])
        })
      }
      let payload: any = {
        SiteID: SiteID.join('~'),
        ClientID: ClientID.join('~'),
        OrderNumber: OrderNumber.join(','),
      }
      this.spinner.show('dataTableLoader')
      this.apiserviceService.get(APIConstant.GetOrderUploadFilesByOrderNo, payload).subscribe(
        (response) => {
          this.responseData = []
          this.responseData = response.data;

          response.data?.map((ele) => {
            ele.siteClientOrder = ele.siteID + '~' + ele.clientID + '~' + ele.orderNumber;
          })

          this.responseDataDisplay = _.groupBy(response.data, itm => itm.siteClientOrder)
          this.spinner.hide('dataTableLoader')
        },
        (error) => {
          this.spinner.hide('dataTableLoader')
          this.commonService.notification(this.validationMessage.toasterClass.error, 'Something went wrong!');
        }
      );
    }
  }

  dateRangeMandatory(){
    if(this.router.url!="/receipts/inbound-documents" && this.router.url!="/shipment-info/outbound-documents"){
      this.isDateRangeVisible=true 
    }
    else{
      //For setting default value "this week" in inbound and outbound documents
      this.selectDateRange = 'thisweek'
      this.setToFromDates({ id: 'thisweek', label: 'This week' })  
    }
  }

  openDownloadPopup(downloadModal) {
    if (this.selectedRows.length === 0) {
      this.commonService.notification(this.validationMessage.toasterClass.warning, 'Select the order first');
      return;
    }
    else {
      // this.modalService.open(downloadModal, {
      //   size: "md modal-dialog-centered",
      //   backdrop: 'static',
      //   windowClass: "modalClass-700",
      //   keyboard: false,
      //   ariaLabelledBy: "modal-basic-title"
      // });
      let SiteID: any = [];
      let ClientID: any = [];
      let OrderNumber: any = [];
      const currentRoute = this.router.url;

      if (currentRoute == '/receipts/inbound-documents') {
        this.selectedRows.forEach((item) => {
          SiteID.push(item.Warehouse)
          ClientID.push(item['Client ID'])
          OrderNumber.push(item.Warehouse + '~' + item['Client ID'] + '~' + item['Warehouse Order No.'])
        })
      }
      else if (currentRoute == '/shipment-info/outbound-documents') {
        this.selectedRows.forEach((item) => {
          SiteID.push(item.Warehouse)
          ClientID.push(item['Client ID'])
          OrderNumber.push(item.Warehouse + '~' + item['Client ID'] + '~' + item['Warehouse Order#'])
        })
      }
      let payload: any = {
        SiteID: SiteID.join('~'),
        ClientID: ClientID.join('~'),
        OrderNumber: OrderNumber.join(','),
      }
      this.spinner.show('dataTableLoader')
      this.apiserviceService.get(APIConstant.GetOrderUploadFilesByOrderNo, payload).subscribe(
        (response) => {
          this.responseData = []
          this.responseData = response.data;
          if(this.responseData.length>0){
          this.responseData.forEach((eachFile)=>{
            if(eachFile.fileName){
            this.downLoadFilePDF(eachFile.filePath,eachFile.fileName)
            }
          })}

          response.data?.map((ele) => {
            ele.downloadHeader = 'SiteID:' + ele.siteID + ', ClientID:' + ele.clientID + ', OrderNumber:' + ele.orderNumber;
          })

          this.downloadDisplay = _.groupBy(response.data, itm => itm.downloadHeader)
          this.spinner.hide('dataTableLoader')
        },
        (error) => {
          this.spinner.hide('dataTableLoader')
          this.commonService.notification(this.validationMessage.toasterClass.error, 'Something went wrong!');
        }
      );
    }
  }

  onCancelClick() {
    if (this.isdeleted == true) {
      this.selectedRows = [];
      this.getReport()
    }
    this.listOfFiles = [];
    this.ondeleteupload = false
    this.uploadModal = ''
    this.modalService.dismissAll();
  }

  isFileNameEmpty(fileName: string | null | undefined): boolean {
    return !fileName || fileName.trim().length === 0;
  }

  submitUpload() {
    this.listOfFiles = [];
    let selectedFieldsData = []
    this.responseData.map(item => {
      if (selectedFieldsData.filter((e => e.siteId == item.siteID && e.clientID == item.clientID && e.orderNumber == item.orderNumber)).length == 0) {
        selectedFieldsData.push({
          "siteId": item.siteID,
          "clientID": item.clientID,
          "orderType": item.orderTYpe,
          "orderNumber": item.orderNumber,
        })
      }
    });
    const formData = new FormData();
    formData.append('UserName', this.userData.username);
    formData.append('typeUploadDocumentsjson', JSON.stringify(selectedFieldsData));
    [...this.fileList].forEach((item) => {
      formData.append('File', item);
    })

    this.spinner.show('dataTableLoader')
    this.apiserviceService.postFile(APIConstant.OrderUploadFiles, formData, {}).subscribe(
      response => {
        if (response.result) {
          this.spinner.hide('dataTableLoader')
          const successMessage = "Files(s) uploaded successfully!";
          this.commonService.notification(this.validationMessage.toasterClass.success, successMessage);
          this.getReport()
          this.selectedRows = [];
          this.reportTableData.forEach(item => {
            if (item.checked == true) {
              item.checked = false;
            }
          });
          this.selectedRows.length === 0
          if (this.modalService) {
            this.modalService.dismissAll();
            this.fileList = [];
            this.listOfFiles = [];
          }
        } else {
          this.spinner.hide('dataTableLoader')
          const errorMessage = response.message || 'Upload failed.';
          this.commonService.notification(this.validationMessage.toasterClass.error, errorMessage);
        }
      },
      error => {
        this.spinner.hide('dataTableLoader')
        let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong!'
        this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
      }
    );
  }

  // deleteUploadFiles(item: any) {
  //   let payload = {
  //     "Id": item.id,
  //     "UserName": this.userData.username,
  //   };
  //   this.apiserviceService.get(APIConstant.DeleteOrderUploadFiles, payload).subscribe(
  //     (response) => {
  //       this.isdeleted = true;
  //       this.openUploadPopup()
  //       this.commonService.notification(this.validationMessage.toasterClass.success, 'File Deleted Successfully');

  //     },
  //     (error) => {
  //       this.commonService.notification(this.validationMessage.toasterClass.error, 'Something went wrong!');
  //     }
  //   );
  // }

  deleteUploadFiles(item: any) {
    let id = item.id;
    if (id) {
      const modalRef = this.modalService.open(this.deleteconfirmation, {
        size: "sm shortBox",
        centered: true,
        backdrop: 'static',
        windowClass: "modalClass-700",
        keyboard: false,
        ariaLabelledBy: "modal-basic-title"
      });
      modalRef.result.then((result) => {
        if (result === 'Yes') {
          let payload = {
            "Id": item.id,
            "UserName": this.userData.username,
          };
          this.apiserviceService.get(APIConstant.DeleteOrderUploadFiles, payload).subscribe(
            (response) => {
              this.isdeleted = true;
              this.openUploadPopup();
              this.commonService.notification(this.validationMessage.toasterClass.success, 'File Deleted Successfully');
            },
            (error) => {
              this.commonService.notification(this.validationMessage.toasterClass.error, 'Something went wrong!');
            }
          );
        } else {
          this.modalService.dismissAll(this.deleteconfirmation);
        }
      });
    }
  }


  onConfirmDelete() {
    this.modalService.dismissAll('Yes');
  }

  

  downloadDocumentPopup(modelContent, order) {
    this.listOfFiles = [];
    let filepath = order.attachFileNamesPath ? order.attachFileNamesPath.split(',') : [];
    let attachedFileSize = order.filzeSize ? order.filzeSize.split(',') : [];
    filepath.map((path, i) => {
      if (path !== "") {
        this.listOfFiles.push({
          name: path.split('/').pop(),
          size: attachedFileSize[i] > 0 ? (attachedFileSize[i] / 1024) / 1024 : 0,
          filepath: path
        })
      }
    })
    this.OpenModal(modelContent)
  }

  closeModel(){
    this.title=""
  }

  OpenModal(modelContent) {
    this.modalService.open(modelContent, {
      size: "md lg modal-dialog-centered",
      backdrop: 'static',
      windowClass: "modalClass-700",
      keyboard: false,
      ariaLabelledBy: "modal-basic-title"
    }).result.then(result => {
      this.closeResult = `Closed with: ${result}`;
    }, reason => {
      this.closeResult = `Dismissed`;
    });
  }


  createSearchForm() {
    this.group.fields.forEach(x => {
      if (x.filterType == 'checkbox') {
        this.form.addControl(x.filterCode, new FormGroup({}))
        if (x.options) {
          x.options.forEach(o => {
            (this.form.get(x.filterCode) as FormGroup).addControl(o.key, new FormControl(x.defaultSelectedValue == 'true' ? true : false))
          })
        }
      }
      else {
        let controlInitVal = [];

        if (x.filterCode === 'SiteID') {
          x.options = this.siteOptions;
          controlInitVal = (this.siteOptions.length === 1) ? [this.siteOptions[0].dropDownValue] : [];
        }

        if(Array.isArray(x.defaultSelectedValue)){
          x.defaultSelectedValue=x.defaultSelectedValue.toString();

        }
        x.defaultSelectedValue = x.defaultSelectedValue ?
          x.allowMultipleSelection ? [x.defaultSelectedValue] : x.defaultSelectedValue.split(',')
          : controlInitVal ? controlInitVal : null;

        // Check if x.defaultSelectedValue is defined before accessing its properties
        // console.log(x.defaultSelectedValue[0].split(',') );
        // console.log(x.defaultSelectedValue);

        if (x.defaultSelectedValue && x.defaultSelectedValue.length > 0) {
          let data: any = x.defaultSelectedValue[0];
          if(Array.isArray(data)){
            data=data.toString();
          }

          this.form.addControl(x.filterCode,
            new FormControl(x.defaultSelectedValue ?
              x.allowMultipleSelection ? data.split(',') : data : null,
              x.isRequired ? Validators.required : null));
        } else {
          this.form.addControl(x.filterCode,
            new FormControl(null, x.isRequired ? Validators.required : null));
        }
      }

    });


    if (this.showDateControls ) {
      if(this.router.url=="/receipts/inbound-documents" || this.router.url=="/shipment-info/outbound-documents"){
        this.form.addControl('dateRange', new FormControl(this.selectDateRange))
        this.form.addControl('SrchDateFrom', new FormControl(this.SrchDateFrom))
        this.form.addControl('SrchDateTo', new FormControl(this.SrchDateTo))
    }
      else{
        this.form.addControl('dateRange', new FormControl(null, [Validators.required]))
        this.form.addControl('SrchDateFrom', new FormControl(null, [Validators.required]))
        this.form.addControl('SrchDateTo', new FormControl(null, [Validators.required]))
    }}
    this.isFormControlsReady = true;
  }

  get formFormControl() { return this.form.controls; }

  shouldDisplayButtons(): boolean {
    const currentRoute = this.router.url;
    const hideButtonsRoutes = ['/receipts/inbound-documents', '/shipment-info/outbound-documents'];

    if (hideButtonsRoutes.includes(currentRoute)) {
      // Hide buttons for specified routes
      return true;
    } else {
      // Show buttons for other routes
      return false;
    }
  }


  binddataenable = false
  onChangeDropdown(event) {

    const bindFieldsArr = (event !== null && event.filterTypeBind !== null) ? event.filterTypeBind.split(',') : [];
    
    if (event && event.onchangevalue == true) {
      let filterTypeBind = event.filterTypeBind.split(',')
      filterTypeBind.forEach(element => {
        this.form.controls[element].setValue(null)
      });

      // if(event.filterTypeBind !== 'ClientID') {
      //   this.binddataenable = false
      //   this.form.reset()
      //   this

      // }
      // return
    }


    if (event && event.value && !event.value.length) {
      bindFieldsArr.map((item) => {
        let bindToEleIndex = this.group.fields.findIndex(x => x.filterCode == item)
        this.group.fields[bindToEleIndex].options = []
      })
    }


    if (event !== null && event.value.length && bindFieldsArr.length) {
      bindFieldsArr.map((item) => {
        let bindToEleIndex = this.group.fields.findIndex(x => x.filterCode == item);
        let payload = {
          username: this.userData.username,
          companyCode: this.userData.userCompanyInfo[0].companyCode
        };
        let apiParamsArr = (this.group.fields[bindToEleIndex].apiParameterName) ? this.group.fields[bindToEleIndex].apiParameterName.split(',') : [];
        let excludeArr = ['UserName', 'UserType']
        apiParamsArr.map((param) => {
          if (!excludeArr.includes(param)) {
            payload[param] = Array.isArray(this.form.controls[param].value) ? this.form.controls[param].value.join() : this.form.controls[param].value
          }
        })
        this.spinner.show('FormLoader')
        this.binddataenable = true
        this.apiserviceService.get(this.group.fields[bindToEleIndex].apiName, payload).subscribe((response) => {
          if (response.result) {
            if (this.group.fields[bindToEleIndex].filterCode == 'ClientID' && this.userData.userCompanyInfo[0].companyCode != 'Arcadia') {
              const tempClientIDs = [];
              // let temRes = response.data.map((item)=>{ 
              //   let itemSplit = item.dropDownValue.split('~')
              //   if(this.selectedAccounts.includes(itemSplit[1])){
              //     tempClientIDs.push(item)
              //   }
              // })
              let sWarehouses = event.value?.map((w) => w.dropDownValue)
              this.userData.allAccounts?.filter(i => i.checked == true && sWarehouses.includes(i.whse_code)).map((item) => {
                tempClientIDs.push({
                  "dropDownValue": item.whse_code + "~" + item.cust_id,
                  "dropDownText": item.cust_name + " (" + item.wh_name + ")"
                })
              })
              this.group.fields[bindToEleIndex].options = tempClientIDs;
            } else {
              this.group.fields[bindToEleIndex].options = response.data;
            }
          }
          this.spinner.hide('FormLoader')
        }, error => {
          this.spinner.hide('FormLoader')
          let errMsg = Object.keys(error.error.errors).length ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong!';
          this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
        })

      });
    }

    let payload;
    //this.isEdit=true
    if (this.binddataenable && this.isEdit) {
      
      payload = {
        username: this.userData.username,
        companyCode: this.userData.userCompanyInfo[0].companyCode,
        SiteID: this.form.value.SiteID.join(),
        ClientID: this.form.value.ClientID.join()
      };
      

      this.group.fields.map((item, bindToEleIndex) => {
        if (item.apiName && bindToEleIndex > 1) {
          this.spinner.show('FormLoader')
          this.apiserviceService.get(item.apiName, payload).subscribe((response) => {
            if (response.result) {
              if (this.group.fields[bindToEleIndex].filterCode == 'ClientID' && this.userData.userCompanyInfo[0].companyCode != 'Arcadia') {
                const tempClientIDs = [];
                // let temRes = response.data.map((item)=>{ 
                //   let itemSplit = item.dropDownValue.split('~')
                //   if(this.selectedAccounts.includes(itemSplit[1])){
                //     tempClientIDs.push(item)
                //   }
                // })
                let sWarehouses = event.value?.map((w) => w.dropDownValue)
                this.userData.allAccounts?.filter(i => i.checked == true && sWarehouses.includes(i.whse_code)).map((item) => {
                  tempClientIDs.push({
                    "dropDownValue": item.whse_code + "~" + item.cust_id,
                    "dropDownText": item.cust_name + " (" + item.wh_name + ")"
                  })
                })
                this.group.fields[bindToEleIndex].options = tempClientIDs;
              } else {
                this.group.fields[bindToEleIndex].options = response.data;
              }
            }
            this.spinner.hide('FormLoader')
          },
            error => {
              this.spinner.hide('FormLoader')
              // let errMsg = Object.keys(error.error.errors).length ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong!';
              // this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
            }
          )
        }
      })


    }
  }
  resetForm() {
    this.form.reset();
    this.reportTableHeader = [];
    this.reportExcludeColumns = [];
    this.reportTableData = [];
    Object.keys(this.form.controls).forEach((k) => {
      let tempItem = this.group.fields.filter((x) => {
        return x.filterCode == k && x.defaultSelectedValue != null
      })[0];
      if (tempItem !== undefined) {
        this.form.get([k]).patchValue(tempItem.defaultSelectedValue);
      }
    });
    this.noDataFlag = false
    this.drivernamerequired = false
    this.drivername = ''
  }

  ngDistroy() {
    this.unsubcribe();
  }

  getReport(bkPayload = null, page: any = 1) {
    this.drivernamerequired = false
    this.drivername = ''

    if (!this.isExportingReport) {
      this.reportTableData = []
      this.reportTableDataExport = []
      this.reportExcludeColumns = []
      this.reportTableHeader = []
      this.reportTableHeaderExport = []
      this.drillDownColumnsArr = []
      this.drillDownTitle = ''
      this.drillDownPayload = ''
      this.drillDownProcedure = ''
      this.page = page
    } else {
      this.reportTableHeaderBackup = this.reportTableHeader
      this.reportTableDataBackup = this.reportTableData
      this.itemsPerPageBackup = this.itemsPerPage
      this.pageBackup = page
      this.page = 1
    }

    if (this.form.valid) {
      let payload = {}
      Object.keys(this.form.value).forEach((k) => {
        if (k === 'SrchDateFrom' || k === 'SrchDateTo' || k === 'InventoryAsof') {
          payload[k] = (this.form.value[k] !== undefined) ? new Date(this.form.value[k]).toLocaleDateString("en-US") : '';
        } else {
          payload[k] = (typeof this.form.value[k] === 'object' && this.form.value[k] !== null)
            ? Array.isArray(this.form.value[k]) ? this.form.value[k].join() : this.form.value[k][k]
            : this.form.value[k];
        }
      })

      let filterOptions = [];
      Object.keys(payload).forEach((key) => {
        if (key != 'dateRange') {
          let value = (payload[key] !== null) ? payload[key] : '';
          filterOptions.push(key + ':' + value)
        }
      })

      let showAll = this.isExportingReport ? 1 : 0
      filterOptions.push('PageNumber' + ':' + page)
      filterOptions.push('ShowAll' + ':' + showAll)

      let inputData = {
        username: this.userData.username,
        reportID: this.reportId,
        params: filterOptions.join('|~')
      }
      this.selectedSearchParams = filterOptions.join('|');
      this.spinner.show('FormLoader')
      this.backPayload.push(inputData)
      this.apiserviceService.post(APIConstant.GetReportDetailbyReportID, inputData, {}).subscribe((response) => {
        if (response.result) {
          if (this.isExportingReport) {
            this.reportTableHeader = []
            this.reportTableData = []
          }


          this.totalItems = response.data.reportData.length ? response.data.reportData[0].TotalRecords : null
          this.itemsPerPage = response.data.pageSize
          

          this.reportExcludeColumns = response.data.excludeColumns.split(',');
          const popupColumns = response.data.popupColumns.split(',');
          const drillColumns = response.data.drillDownColumns?.split(',');

          // //Based on Voided Invoices, title of pop up is to be changed
          // for(let i=0;i<response.data.reportData.length;i++){
          //   this.isHightlightVoid=response.data.reportData[i].VoidedInvoices
          //   console.log("this.isHightlightVoid",this.isHightlightVoid)
            
          //     this.VoidHeading.push(this.isHightlightVoid)
          // }
            
          
          // for(let i=0;i<this.VoidHeading.length;i++){
          //   if(this.VoidHeading[i]==true){
          //     // this.title="Voided Invoices"
          //   }
          //   else{
          //     // this.title=""
          //   }
          // }

          popupColumns.map((x) => {
            let tempArr = x.split('_')
            this.popupColumnsArr.push({
              key: tempArr[0],
              value: tempArr[1]
            })
          });
          drillColumns?.map((x) => {
            let tempArr = x.split('|')
            this.drillDownColumnsArr.push({
              showColumn: tempArr[0],
              dbColumn: tempArr[1],
              procedure: tempArr[2]
            })
          })

          const tableHeaderColumns = response.data.columnAlignment.split(',');
          tableHeaderColumns.map((x) => {
            let tempArr = x.split('~')
            if (!this.reportExcludeColumns.includes(tempArr[0].trim())) {
              const popArr = this.popupColumnsArr.filter((pop) => pop.key == tempArr[0].trim());
              
              const drillArr = this.drillDownColumnsArr.filter((drill) => drill.showColumn == tempArr[0].trim());

              this.reportTableHeader.push({
                columnName: tempArr[0].trim(),
                columnAlignment: tempArr[1] ? tempArr[1].trim().toLowerCase() : '',
                showPopup: popArr.length ? true : false,
                showDrillDown: drillArr.length ? true : false,
                DrillDowndbColumn: drillArr.length ? drillArr[0].dbColumn.trim() : '',
                DrillDownprocedure: drillArr.length ? drillArr[0].procedure.trim() : '',
                showPopupColumn: popArr.length ? popArr[0].value.trim() : ''
              })
              
              
            }
          });

          this.reportTableData = response.data.reportData
          
          if (this.isExportingReport) {
            if (this.exportType == 'excel') {
              setTimeout(() => this.exportexcel(), 2300);
            } else {
              setTimeout(() => this.SavePDF(), 2300);
            }
          } else {
            this.spinner.hide('FormLoader')
          }
          this.noDataFlag = response.data.reportData.length ? false : true;

        }

      }, error => {
        this.spinner.hide('FormLoader')
        let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
        this.commonService.notification(this.validationMessage.toasterClass.error, errMsg)
      })

    }
  }
  getFileNameFromPath(filePath: string): string {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  }
  getAndEditReportFilters() {
    let params = {
      usertype: this.userData.userCompanyInfo[0].userType,
      reportid: this.reportId,
      username: this.userData.username,
      scheduleid: this.scheduleid
    };

    this.spinner.show('FormLoader');
    this.apiserviceService.get(APIConstant.GetFilterReportData, params).subscribe((response) => {
      if (response.result) {
        let temArr = response.data.filter((item) => {
          if (item.filterType === 'checkbox') {
            item.options = [{ key: item.filterCode, label: item.filterLabel }];
          }

          if (item.filterType === "date") {
            this.showDateControls = true;
            this.selectDateRange = 'custom'
            this.dateControlsDisabled = false;
            this.SrchDateFrom = new Date(response.data.find(item => item.filterCode === 'SrchDateFrom')?.defaultSelectedValue)
            this.SrchDateTo = new Date(response.data.find(item => item.filterCode === 'SrchDateTo')?.defaultSelectedValue)
          }

          return item.filterType !== "date";
        });
        this.group.fields = temArr;
      }
      this.createSearchForm();
      this.spinner.hide('FormLoader');
    }, error => {
      this.spinner.hide('FormLoader');
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
    });
  }

  getAndSetReportFilters() {
    let params = {
      usertype: this.userData.userCompanyInfo[0].userType,
      reportid: this.reportId,
      username: this.userData.username,
    };

    this.spinner.show('FormLoader');
    this.apiserviceService.get(APIConstant.GetFilterReportData, params).subscribe((response) => {
      if (response.result) {
        let temArr = response.data.filter((item) => {
          if (item.filterType === 'checkbox') {
            item.options = [{ key: item.filterCode, label: item.filterLabel }];
          }

          if (item.filterType === "date") {
            this.showDateControls = true;
            this.defaultSrchDateFrom = response.data.find(item => item.filterCode === 'SrchDateFrom')?.defaultSelectedValue;
            this.defaultSrchDateTo = response.data.find(item => item.filterCode === 'SrchDateTo')?.defaultSelectedValue;
          }

          return item.filterType !== "date";
        });
        this.group.fields = temArr;
      }
      this.createSearchForm();
      this.spinner.hide('FormLoader');
    }, error => {
      this.spinner.hide('FormLoader');
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
    });
  }

  setToFromDates(event) {
    if (event && event.id === 'custom') {
      this.dateControlsDisabled = false;
    }
    if (event && event.id === 'thisweek') {
      this.dateControlsDisabled = true;
      var curr = new Date;
      var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
      var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
      this.SrchDateFrom = firstday;
      this.SrchDateTo = lastday;
    } else if (event && event.id === 'yesterday') {
      this.dateControlsDisabled = true;
      var curr = new Date;
      var firstday = new Date(curr.setDate(curr.getDate() - 1));
      var lastday = new Date(curr.setDate(curr.getDate()));
      this.SrchDateFrom = firstday;
      this.SrchDateTo = lastday;
    } else if (event && event.id === 'today') {
      this.dateControlsDisabled = true;
      var curr = new Date;
      var firstday = new Date(curr.setDate(curr.getDate()));
      var lastday = new Date(curr.setDate(curr.getDate()));
      this.SrchDateFrom = firstday;
      this.SrchDateTo = lastday;
    } else if (event && event.id === 'lastweek') {
      this.dateControlsDisabled = true;
      var curr = new Date;
      var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() - 7));
      var lastday = new Date(curr.setDate(curr.getDate() - (curr.getDay()) + 6));
      this.SrchDateFrom = firstday;
      this.SrchDateTo = lastday;
    } else if (event && event.id === 'thismonth') {
      this.dateControlsDisabled = true;
      var curr = new Date;
      var firstday = new Date(curr.getFullYear(), curr.getMonth(), 1);
      var lastday = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
      this.SrchDateFrom = firstday;
      this.SrchDateTo = lastday;
    }
    else if (event && event.id === 'lastmonth') {
      this.dateControlsDisabled = true;
      var curr = new Date;
      var firstday = new Date(curr.getFullYear(), curr.getMonth() - 1, 1);
      var lastday = new Date(curr.getFullYear(), curr.getMonth(), 0);
      this.SrchDateFrom = firstday;
      this.SrchDateTo = lastday;
    }
    else {
      this.SrchDateFrom = null;
      this.SrchDateTo = null;
    }
  }

  callDrillDownReport(DrillDowndbColumn = null, DrillDownprocedure = null, value = null, siteID = null, clientID = null, bkPayload = null, page: any = 1) {
    if (!this.isExportingReport) {
      this.isMainReport = false
      this.isDrillDownReport = true
      this.reportTableData = []
      this.reportTableHeader = []
      this.drillDownColumnsArr = []
      this.page = page
    } else {
      this.reportTableHeaderBackup = this.reportTableHeader
      this.reportTableDataBackup = this.reportTableData
      this.itemsPerPageBackup = this.itemsPerPage
      this.pageBackup = page
      this.page = 1
    }

    let showAll = this.isExportingReport ? 1 : 0
    let filterOptions = [
      'SiteID:' + siteID,
      'ClientID:' + clientID,
      `${DrillDowndbColumn}:` + value,
      'PageNumber:' + page,
      'ShowAll:' + showAll
    ]

    let payload = bkPayload ? bkPayload : {
      "procedureName": DrillDownprocedure,
      "username": this.userData.username,
      "params": filterOptions.join('|~')
    }
    if (bkPayload) {
      this.drillDownProcedure = bkPayload.procedureName
      let tpArr = bkPayload.params.split('|~')
      tpArr.pop()
      tpArr.pop()
      this.drillDownfilterOptions = tpArr
      this.drillDownPayload = tpArr.join(', ')
    } else {
      let tpParamsArr = filterOptions
      tpParamsArr.pop()
      tpParamsArr.pop()
      this.drillDownPayload = tpParamsArr.join(', ')

      this.drillDownProcedure = DrillDownprocedure
      this.drillDownfilterOptions = filterOptions
    }



    this.spinner.show('FormLoader')
    this.backPayload.push(payload)
    this.apiserviceService.post(APIConstant.GetDrillDownReport, payload, {}).subscribe((response) => {
      if (response.result) {
        if (this.isExportingReport) {
          this.reportTableHeader = []
          this.reportTableData = []
        }

        this.totalItems = response.data.reportData.length ? response.data.reportData[0].TotalRecords : null
        this.itemsPerPage = response.data.pageSize

        this.reportExcludeColumns = response.data.excludeColumns.split(',');
        const popupColumns = response.data.popupColumns.split(',');
        const drillColumns = response.data.drillDownColumns?.split(',');
        this.drillDownTitle = response.data.drillDownTitle

        popupColumns.map((x) => {
          let tempArr = x.split('_')
          this.popupColumnsArr.push({
            key: tempArr[0],
            value: tempArr[1]
          })
        });
        drillColumns?.map((x) => {
          let tempArr = x.split('|')
          this.drillDownColumnsArr.push({
            showColumn: tempArr[0],
            dbColumn: tempArr[1],
            procedure: tempArr[2]
          })
        })

        const tableHeaderColumns = response.data.columnAlignment.split(',');
        tableHeaderColumns.map((x) => {
          let tempArr = x.split('~')
          if (!this.reportExcludeColumns.includes(tempArr[0].trim())) {
            const popArr = this.popupColumnsArr.filter((pop) => pop.key == tempArr[0].trim());
            const drillArr = this.drillDownColumnsArr.filter((drill) => drill.showColumn == tempArr[0].trim());
            this.reportTableHeader.push({
              columnName: tempArr[0].trim(),
              columnAlignment: tempArr[1].trim().toLowerCase(),
              showPopup: popArr.length ? true : false,
              showDrillDown: drillArr.length ? true : false,
              DrillDowndbColumn: drillArr.length ? drillArr[0].dbColumn.trim() : '',
              DrillDownprocedure: drillArr.length ? drillArr[0].procedure.trim() : '',
              showPopupColumn: popArr.length ? popArr[0].value.trim() : ''
              
            })
          }
        });

        this.reportTableData = response.data.reportData
        if (this.isExportingReport) {
          if (this.exportType == 'excel') {
            setTimeout(() => this.exportexcel(), 2300);
          } else {
            setTimeout(() => this.SavePDF(), 2300);
          }
        } else {
          this.spinner.hide('FormLoader')
        }
        this.noDataFlag = response.data.reportData.length ? false : true
      }
      this.spinner.hide('FormLoader')
    }, error => {
      this.spinner.hide('FormLoader')
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg)
    })
  }

  onClick(e: MouseEvent, index: number) {
    const container =document.getElementById('container')
    const img = document.getElementById('image');

    this.isZoomed = !this.isZoomed; // Toggle zoom state for the specific image
    if (this.isZoomed) {
      container.style.overflow = 'hidden';
     img.style.transition = 'transform 0.3s ease';
      img.style.transform = 'scale(2)';
      img.style.cursor = 'zoom-out';

      const rect = img.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      img.style.transformOrigin = `${(offsetX / rect.width) * 100}% ${
        (offsetY / rect.height) * 100
      }%`;
    } else {
      img.style.transform = 'scale(1)';
      img.style.cursor = 'zoom-in';
    }
  }

  

  onMouseDown(e: MouseEvent, index: number) {
    let container=document.getElementById('container')
    this.pos = {
      // The current scroll
      left: container.scrollLeft,
      top: container.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
  }

  mouseMoveHandler(e: MouseEvent, index: number) {
    let container=document.getElementById('container')
   // How far the mouse has been moved
   const dx = (e.clientX - this.pos.x) * 2;
   const dy = (e.clientY - this.pos.y) * 3;

   // Scroll the element
   container.scrollTop = this.pos.top - dy;
   container.scrollLeft = this.pos.left - dx;
  }

  

  setAndOpenDescriptionPopup(field, content, docType = 'O',voidTitle:any='') {
   
    this.title=""
    voidTitle?this.title=' : Voided Invoice':''
    this.currentPopupField = ''
    this.modelHeading = field + ' Information'
    if (field == 'Media File(s)') {
      
      this.imgArr = content.split(',')
      this.currentPopupField = field
      // let tempContent = ''
      // imgArr.forEach(function (item) {
      //   tempContent += `<img src="${item}"><br></br>`
      // })
      // this.modelText = tempContent
      this.openlinksPopupMaxModel(this.linksPopup)
      return
    }
    else if (field == 'Invoice Number') {
      if (content) {
        this.iframePdfUrl = content
        let tempContent = `<iframe width="100%" height="500px" src="${content}"></iframe>`
        this.modelText = tempContent
      } else {
        this.modelText = 'No attachment found!'
      }

    }
    else if (field == 'Shipper / Consignee') {
      this.modelHeading = docType == 'O' ? 'Consignee Information' : 'Shipper Information'
      this.modelText = content
    }
    
    else if (field == 'Download(s)') {
      
      this.modelHeading = 'View Files(s)'
      this.modelText = content.length ? content.split(',') : []
      this.currentPopupField = field
    }
    else if (field == 'Doc.Download(s)') {
      
      this.modelHeading = 'View Files(s)'
      this.modelText = content
      this.currentPopupField = field
      this.currentUrl=this.modelText.split("/").pop()
      
      this.downLoadFilePDF(this.modelText,this.currentUrl)
      return 
      
    }
    else if (field == 'BOL Download') {
      
      //this.modelHeading = 'View Files(s)'
      this.modelText = content
      this.currentPopupField = field
      this.currentUrl=this.modelText.split("/").pop()
      
      this.downLoadFilePDF(this.modelText,this.currentUrl)
      return 
      
    }
    else if (field == 'Other Documents Dwnld') {
      
      //When we want to show the files/pdf in popup
      // this.modelText = content.length ? content.split(',') : []
      // this.currentPopupField = field

      //When we want to direct download the files/pdf
      let modelTextArray:any[]=[]
      modelTextArray=content.split(",")
      this.currentPopupField = field
      modelTextArray.forEach((eachFile)=>{
        this.currentUrl=eachFile.split("/").pop()
        this.downLoadFilePDF(eachFile,this.currentUrl)
      })
      return
      
    }

    else if (field == 'Inbound Docs') {
      
      this.modelHeading = 'View Files(s)'
      this.modelText = content
      this.currentPopupField = field
      this.currentUrl=this.modelText.split("/").pop()
      
      this.downLoadFilePDF(this.modelText,this.currentUrl)
      return 
      
    }
    else if (field == 'Document Docs') {
      
      this.modelHeading = 'View Files(s)'
      this.modelText = content.length ? content.split(',') : []
      this.currentPopupField = field
      
    }


    else {
      this.iframePdfUrl = ''
      this.modelText = content
    }
    this.openlinksPopupModel(this.linksPopup);
  }

  // downloadExcelFile(): void {
  //   const link = document.createElement('a');
  //   link.setAttribute('href', this.modelText);
    
  //   link.setAttribute('download', 'this.modelText.split("/").pop()');
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  /* function for download files  */
  downLoadFilePDF(filepath, filename) {
    this.spinner.show('FormLoader');
    let url = filepath;
    fetch(url).then(res => res.blob()).then(file => {
      let temUrl = URL.createObjectURL(file);
      let aTag = document.createElement("a");
      aTag.href = temUrl;
      aTag.download = filename;
      document.body.appendChild(aTag)
      aTag.click()
      aTag.remove()
      this.spinner.hide('FormLoader');
    })
  }



  openlinksPopupModel(modelContent) {
    //this.spinner.show('dataTableLoader');
    this.modalService.open(modelContent, {
      size: "lg modal-dialog-centered",
      backdrop: 'static',
      windowClass: "modalClass-700",
      keyboard: false,
      ariaLabelledBy: "modal-basic-title"
    }).result.then(result => {
     
      this.closeResult = `Closed with: ${result}`;
     
    }, reason => {
      
      this.closeResult = `Dismissed`;
      
    }
    );
    

  }

  // openlinksPopupMaxModel(modelContent) {
  //   this.spinner.show('dataTableLoader');
  //   this.modalService.open(modelContent, {
  //     size: "xl modal-dialog-centered",
  //     backdrop: 'static',
  //     windowClass: "modalClass-700 modal-autoheight",
  //     keyboard: false,
  //     ariaLabelledBy: "modal-basic-title"
  //   }).result.then(result => {
  //     this.closeResult = `Closed with: ${result}`;
  //     this.spinner.hide('dataTableLoader');
  //   }, reason => {
  //     this.closeResult = `Dismissed`;
  //     this.spinner.hide('dataTableLoader');
  //   }
    
  //   );
  //   //this.spinner.hide('dataTableLoader');
  // }
  openlinksPopupMaxModel(modelContent) {
    
    this.spinner.show('dataTableLoader');  // Show the loader when the modal is about to open
    this.isLoading=true
    
    const modalRef = this.modalService.open(modelContent, {
      size: "xl modal-dialog-centered",
      backdrop: 'static',
      windowClass: "modalClass-700 modal-autoheight",
      keyboard: false,
      ariaLabelledBy: "modal-basic-title"
    });
  
    console.log('Modal is now open');
    modalRef.result.then(result => {
      console.log('Modal closed with result:', result);  // Should be triggered when the modal closes
      this.closeResult = `Closed with: ${result}`;
      this.spinner.hide('dataTableLoader');  // Hide the loader when modal closes
    }, reason => {
      console.log('Modal dismissed with reason:', reason);  // Should be triggered when modal is dismissed
      this.closeResult = `Dismissed`;
      this.spinner.hide('dataTableLoader');  // Hide the loader when modal is dismissed
    });
   
  }
  

  /* function for download files  */
  downLoadFile(filepath) {
    this.spinner.show('FormLoader');
    let url = filepath;
    fetch(url).then(res => res.blob()).then(file => {
      let temUrl = URL.createObjectURL(file)
      let aTag = document.createElement("a")
      aTag.href = temUrl
      aTag.download = filepath.split("/").pop()
      document.body.appendChild(aTag)
      aTag.click()
      aTag.remove()
      this.spinner.hide('FormLoader');
    })
  }

  /* function for download files  */
  downLoadFileForReport(filepath, filename) {
    this.spinner.show('FormLoader');
    let url = filepath;
    fetch(url).then(res => res.blob()).then(file => {
      let temUrl = URL.createObjectURL(file);
      let aTag = document.createElement("a");
      aTag.href = temUrl;
      aTag.download = filename;
      document.body.appendChild(aTag)
      aTag.click()
      aTag.remove()
      this.spinner.hide('FormLoader');
    })
  }


  exportexcel(): void {
    /* pass here the table id */
    let element = document.getElementById('header-fixed');
    
    // Remove hyperlinks from the HTML content
    const removeHyperlinks = (element: HTMLElement) => {
        const links = element.getElementsByTagName('a');
        for (let i = links.length - 1; i >= 0; i--) {
            const link = links[i];
            link.removeAttribute('href');
            link.style.color = 'black'; // Set color to black
            link.style.textDecoration = 'none'; // Remove underline
        }
    };
    
    if (element) {
        removeHyperlinks(element);
    }

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { origin: "A6", raw: true });
    

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // Add metadata to the sheet
    let SearchParams = this.selectedSearchParams.split('|');
    SearchParams.pop();
    SearchParams.pop();

    if (this.isDrillDownReport) {
        XLSX.utils.sheet_add_aoa(ws, [
            ["Report Title", this.drillDownTitle],
            ["Search Params", this.drillDownPayload],
            ["Generated At", new Date().toLocaleDateString("en-US") + " " + new Date().toLocaleTimeString()]
        ], { origin: "A1" });
    } else {
        XLSX.utils.sheet_add_aoa(ws, [
            ["Report ID", this.reportId],
            ["Report Title", this.reportTitle],
            ["Search Params", SearchParams.join('|')],
            ["Generated At", new Date().toLocaleDateString("en-US") + " " + new Date().toLocaleTimeString()]
        ], { origin: "A1" });
    }
    
    // Convert all cells to text format
    const range = XLSX.utils.decode_range(ws['!ref']);
    let columnsName = [];
    if(this.router.url=='/receipts/receipt-summary-detail-report'){
      
      const numColumns = range.e.c - range.s.c + 1; // Calculate the number of columns

    // Create a column width array with 100px for each column
    const columnWidths = Array(numColumns).fill({ wpx: 150 });

    // Apply the column widths to the worksheet
    
      for (let C = range.s.c; C <= range.e.c; ++C){
        const address = { c: C, r: 5 };
        const cell_ref = XLSX.utils.encode_cell(address);
        columnsName.push(ws[cell_ref].v)
        
      }
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const address = { c: C, r: R };
            const cell_ref = XLSX.utils.encode_cell(address);
            if (!ws[cell_ref]) continue;

            // Ensure cell data is treated as plain text
            // ws[cell_ref].t = 's'; // 's' is for string
            
            

            let testArray=[]
            testArray.push(columnsName.indexOf("Received Quantity"))
            testArray.push(columnsName.indexOf("Gross Weight"))
            testArray.push(columnsName.indexOf("Est. Weight"))
            testArray.push(columnsName.indexOf("Catch Weight"))
            testArray.push(columnsName.indexOf("Pallets"))
           

            if (R > 5 && (testArray.includes(C))) { 
              // Remove commas from the value string before parsing it
              const value = ws[cell_ref].v.replace(/,/g, '');
              
              // Parse the cleaned value as a float
              const numericValue = value;
              
              // Check if the value is a valid number
              if (!isNaN(numericValue)) {
                  ws[cell_ref].t = 'n'; // 'n' is for number
                  ws[cell_ref].v = numericValue; // Store the parsed number
              } else {
                  ws[cell_ref].t = 's'; 
              }
          } else {
              ws[cell_ref].t = 's'; // 's' is for string
          }

            // Apply plain text style
            if (!ws[cell_ref].s) ws[cell_ref].s = {};

            //Headers of table Formatting
            if(R == 5){
              ws[cell_ref].s.fill = {
                patternType: 'solid',
                fgColor: { rgb: '233756' },
                bgColor: { rgb: '233756' },
              };
              ws[cell_ref].s.font = {
                color: { rgb: 'FFFFFF' }, // White color
                sz: 10,
                underline: false ,
                bold:true
            };
            }else if(R>=0 && R<=3){
              ws[cell_ref].s.font = {
                bold: true,
                color: { rgb: '000000' }, // White color
                sz: 10,
                underline: false ,
            };
            }
            else{
                ws[cell_ref].s.font = {
                color: { rgb: '000000' }, // Black color
                underline: false ,// Remove underline
                sz: 10,
            };
            }

        }
    }
    ws['!cols'] = columnWidths;
    ws['!autofilter'] = { ref:'A6:AF6' };
    }

    else{
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const address = { c: C, r: R };
            const cell_ref = XLSX.utils.encode_cell(address);
            if (!ws[cell_ref]) continue;

            // Ensure cell data is treated as plain text
            ws[cell_ref].t = 's'; // 's' is for string

            // Apply plain text style
            if (!ws[cell_ref].s) ws[cell_ref].s = {};
            ws[cell_ref].s.font = {
                color: { rgb: "000000" }, // Black color
                underline: false // Remove underline
            };
        }
    }


    }
   
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    /* save to file */
    XLSX.writeFile(wb, this.isDrillDownReport ? this.drillDownTitle + '_' + Date.now() + '.xlsx' : this.reportTitle + '_' + Date.now() + '.xlsx');

    // Restore previous states
    this.itemsPerPage = this.itemsPerPageBackup;
    this.page = this.pageBackup;
    this.reportTableData = this.reportTableDataBackup;
    this.reportTableHeader = this.reportTableHeaderBackup;
    this.spinner.hide('FormLoader');
    this.backPayload.pop();
    this.isExportingReport = false;
}




  @ViewChild('header-fixed', { static: true }) content: ElementRef;
  public SavePDF(): void {
    //const DATA = this.content.nativeElement;  

    let DATA: any = document.getElementById('header-fixed');
    html2canvas(DATA).then((canvas) => {
      var imgData = canvas.toDataURL('image/png');
      var margin = 2;
      var imgWidth = 210 - 2 * margin;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 40;
      doc.setFontSize(7);

      /* Selected Filters for selected search params */
      let SearchParams = this.selectedSearchParams.split('|');
      SearchParams.pop()
      SearchParams.pop()


      if (this.isDrillDownReport) {
        var splitSelected = doc.splitTextToSize(`Selected Filters:  ${this.drillDownPayload}`, 180);
        doc.text(`Report Title: ${this.drillDownTitle}`, 5, 10);
        doc.text(`Generated At: ${new Date().toLocaleDateString("en-US") + " " + new Date().toLocaleTimeString()}`, 5, 15);
        doc.text(splitSelected, 5, 20);
      } else {
        var splitSelected = doc.splitTextToSize(`Selected Filters:  ${SearchParams.join('|')}`, 180);
        doc.text(`Report ID: ${this.reportId}`, 5, 5);
        doc.text(`Report Title: ${this.reportTitle}`, 5, 10);
        doc.text(`Generated At: ${new Date().toLocaleDateString("en-US") + " " + new Date().toLocaleTimeString()}`, 5, 15);
        doc.text(splitSelected, 5, 20);
      }
      doc.setFontSize(14);

      doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      let pdfTitle = this.isDrillDownReport ? this.drillDownTitle : this.reportTitle
      doc.save(`${pdfTitle + '_' + Date.now()}.pdf`);

      this.itemsPerPage = this.itemsPerPageBackup
      this.page = this.pageBackup
      this.reportTableData = this.reportTableDataBackup
      this.reportTableHeader = this.reportTableHeaderBackup
      this.spinner.hide('FormLoader')
      this.backPayload.pop()
      this.isExportingReport = false
    });
  }

  /**
   * 
   * @returns - Print Packing List ON Report ID - OUTBR022.00
   */
  printPackingList() {
    const printableData = this.reportTableData.filter((x) => x.checked == true && x['Client ID']).map((item) => {
      return item['Warehouse'] + '~' + item['Client ID'] + '~' + item['Warehouse Order#']
    })

    if (!printableData.length) {
      this.commonService.notification(this.validationMessage.toasterClass.info, 'You have to select atleast one item to print.')
      return false
    }

    let payload = { outboundOrdersList: printableData.join(',') }
    this.spinner.show('FormLoader')
    this.apiserviceService.post(APIConstant.GetPackingListInformation, payload, {}).subscribe((response) => {
      if (response.result) {
        let pdfs = response.data ? response.data.split(',') : []
        pdfs?.forEach(i => {
          window.open(i)
        })
      }
      this.spinner.hide('FormLoader')
    }, error => {
      this.spinner.hide('FormLoader')
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg)
    })

  }

  printDocument() {
    const printableData = this.reportTableData.filter((x) => x.checked == true && x['Client ID']).map((item) => {
      return item['Warehouse'] + '~' + item['Client ID'] + '~' + item['Warehouse Order No.']
    })

    if (!printableData.length) {
      this.commonService.notification(this.validationMessage.toasterClass.info, 'You have to select atleast one item to print.')
      return false
    }

    let payload = { inboundOrdersList: printableData.join(',') }
    this.spinner.show('FormLoader')
    this.apiserviceService.post(APIConstant.GetInboundWarehouseRcptInfo, payload, {}).subscribe((response) => {
      if (response.result) {
        let pdfs = response.data ? response.data.pdfPath.split(',') : []
        pdfs?.forEach(i => {
          window.open(i)
        })
      }
      this.spinner.hide('FormLoader')
    }, error => {
      this.spinner.hide('FormLoader')
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg)
    })

  }

  // uploadFiles(){

  // }

  // downloadFiles(){

  // }

  //Email Report
  emailReport() {
    if (!this.allUserEmails.length) {
      let params = {
        UserName: this.userData.username
      }
      this.spinner.show('FormLoader')
      this.apiserviceService.get(APIConstant.GetAllUserEmails, params).subscribe((response) => {
        if (response.result) {
          this.allUserEmails = response.data;
          this.modalService.dismissAll();
          this.openReportEmailPopupModel(this.EmailReportPopup)
          this.createSearchForm();
        }
        this.spinner.hide('FormLoader')
      }, error => {
        this.spinner.hide('FormLoader')
        let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
        this.commonService.notification(this.validationMessage.toasterClass.error, errMsg)
      })
    } else {
      this.modalService.dismissAll();
      this.openReportEmailPopupModel(this.EmailReportPopup)
    }
  }

  submitReportEmail() {
    let payload = {}
    Object.keys(this.form.value).forEach((k) => {
      if (k === 'SrchDateFrom' || k === 'SrchDateTo' || k === 'InventoryAsof') {
        payload[k] = (this.form.value[k] !== undefined) ? new Date(this.form.value[k]).toLocaleDateString("en-US") : '';
      } else {
        payload[k] = (typeof this.form.value[k] === 'object' && this.form.value[k] !== null)
          ? Array.isArray(this.form.value[k]) ? this.form.value[k].join() : this.form.value[k][k]
          : this.form.value[k];
      }
    })

    let filterOptions = [];
    Object.keys(payload).forEach((key) => {
      if (key != 'dateRange') {
        let value = (payload[key] !== null) ? payload[key] : '';
        filterOptions.push(key + ':' + value)
      }
    })

    let params: any = {}
    if (this.isDrillDownReport) {
      // this.drillDownfilterOptions.push('PageNumber:'+1)
      this.drillDownfilterOptions.push('ShowAll:' + 1)
      params = {
        username: this.userData.username,
        reportTitle: this.drillDownTitle,
        params: this.drillDownfilterOptions.join('|~'),
        isEmail: true,
        emailIds: this.emailReportForm.value['emails'].join(','),
        comments: this.emailReportForm.value['comment'],
        procedureName: this.drillDownProcedure
      }
    } else {
      filterOptions.push('showAll:' + 1)
      params = {
        username: this.userData.username,
        reportID: this.reportId,
        reportTitle: this.reportTitle,
        params: filterOptions.join('|~'),
        isEmail: true,
        emailIds: this.emailReportForm.value['emails'].join(','),
        comments: this.emailReportForm.value['comment']
      }
    }


    
    this.spinner.show('popupForm')
    this.apiserviceService.post(APIConstant.GetReportDetailbyReportID, params, {}).subscribe((response) => {
      if (response.result) {
        this.commonService.notification(this.validationMessage.toasterClass.success, `${params.reportTitle} sent on emails successfully`)
      }
      this.emailReportForm.reset()
      this.modalService.dismissAll()
      this.spinner.hide('popupForm')
    }, error => {
      this.spinner.hide('popupForm')
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg)
    })
  }

  openReportEmailPopupModel(modelContent) {
    this.modalService.open(modelContent, {
      size: "md",
      backdrop: 'static',
      windowClass: "modalClass-700",
      keyboard: false,
      ariaLabelledBy: "modal-basic-title"
    }).result.then(result => {
      this.closeResult = `Closed with: ${result}`;
    }, reason => {
      this.closeResult = `Dismissed`;
    }
    );
  }

  setEmailCounter(event) {
    this.selectedEmailCounter = this.emailReportForm.get('emails').value.length
  }
  onSelectAll() {
    const selected = this.allUserEmails.map(x => x.dropDownValue)
    this.emailReportForm.get('emails').patchValue(selected)
    this.selectedEmailCounter = this.allUserEmails.length;
  }

  onClearAll() {
    this.emailReportForm.get('emails').patchValue([])
    this.selectedEmailCounter = 0
  }

  goBackReport() {
    this.backPayload.pop()
    let payload = this.backPayload[this.backPayload.length - 1]
    this.backPayload.pop()

    if (payload.procedureName == undefined) {
      this.isMainReport = true
      this.isDrillDownReport = false
      this.getReport(payload)
    } else {
      this.isMainReport = false
      this.isDrillDownReport = true
      this.callDrillDownReport(null, null, null, null, null, payload)
    }
  }

  funPagination(page: any = 1) {
    let payload = this.backPayload[this.backPayload.length - 1]


    let tmpArr = payload.params.split('|~')
    let HoldArr = tmpArr.map((x) => {
      if (x.includes('PageNumber')) {
        return x = 'PageNumber:' + page
      }
      else {
        return x;
      }
    })

    payload.params = HoldArr.join('|~')

    if (!this.isDrillDownReport) {
      this.isMainReport = true
      this.isDrillDownReport = false
      this.getReport(null, page)
    } else {
      this.isMainReport = false
      this.isDrillDownReport = true
      this.callDrillDownReport(null, null, null, null, null, payload, page)
    }
  }

  exportReport(exportType) {
    this.exportType = exportType
    this.isExportingReport = true
    //this.backPayload.pop() 
    let payload = this.backPayload[this.backPayload.length - 1]
    //this.backPayload.pop() 

    if (payload.procedureName == undefined) {
      this.isMainReport = true
      this.isDrillDownReport = false
      this.getReport(payload, this.page)
    } else {
      this.isMainReport = false
      this.isDrillDownReport = true
      this.callDrillDownReport(null, null, null, null, null, payload, this.page)
    }
  }

  checkAllCheckBox(ev: any) {
    this.reportTableData.forEach(x => x.checked = ev.target.checked);
    this.checkAndSetFlags();
  }

  isAllCheckBoxChecked() {
    return this.reportTableData.every(p => p.checked);
  }

  checkAndSetFlags() {
    const isAnySelected = this.reportTableData.some(x => x.checked);

    this.drivernamerequired = this.reportTableData.some(x => x.checked && x['BOL Signed/Printed'] === 'No');
    this.reflectError = this.drivernamerequired ? false : true;
    this.drivername = this.reflectError ? '' : this.drivername;

    // Populate selectedRows array with selected row data
    this.selectedRows = this.reportTableData.filter(x => x.checked);

    this.anyItemSelect = isAnySelected;
  }
  getDriverValue(event) {
    if (event.target.value) {
      this.reflectError = false
      return
    }

    this.reflectError = true

  }

  printTicket() {
    let signRequired = false
    const printableData = this.reportTableData.filter((x) => x.checked == true && x['Client ID']).map((item) => {
      const bolSignedValue = item['BOL Signed/Printed'] === 'Yes' ? 1 : 0;
      if (bolSignedValue === 0) {
        signRequired = true;
      }
      return item['Warehouse'] + '~' + item['Client ID'] + '~' + item['Warehouse Order#'] + '~' + bolSignedValue;
    });
    const printableData2 = this.reportTableData.filter((x) => x.checked == true && x['Client ID']).map((item) => {
      if (item['BOL Signed/Printed'] == 'No') {
        signRequired = true
      }
      return item['Warehouse'] + '~' + item['Client ID'] + '~' + item['Warehouse Order#'] //+'~'+item['BOL Signed']
    })
    // if(signRequired && this.signatureImg==''){
    //   this.commonService.notification(this.validationMessage.toasterClass.info, 'Driver signature not captured yet, Please try agian.')
    //   return false  
    // }

    if (!printableData.length) {
      this.commonService.notification(this.validationMessage.toasterClass.info, 'You have to select atleast one item to print.')
      return false
    }

    let payload = {
      'bolPrintCopy': this.form.value.BOLPrintCopy.join(','),
      'outboundOrdersListID': printableData.join(','),
      'signatureImage': this.signatureImg ? this.signatureImg : null,
      'userName': this.userData.username,
      'driverName': this.drivername,
      'outboundOrdersListID2': printableData2.join(','),
      'IsRegenerate': this.isRegenerate,
    }

    this.spinner.show('FormLoader')
    this.apiserviceService.post(APIConstant.GetDTBOL, payload, {}).subscribe((response) => {
      if (response.result) {
        this.signatureImg = ''
        let pdfs = response.data.pdfPath ? response.data.pdfPath.split(',') : []
        pdfs?.forEach(i => {
          window.open(i)
        })
        //window.open(response.data.pdfPath)
        // window.open(response.data.pdfPath)
        // window.location.reload();
        this.getReport()
      }
      this.spinner.hide('FormLoader')
    }, error => {
      this.spinner.hide('FormLoader')
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg)
    })
  }

  /********
   * MANAGE SIGNATURE
   */

  StartSignPrint() {
    if (!this.drivername && this.drivernamerequired) {
      this.reflectError = true
      return false
    }

    let signRequired = false
    const printableData = this.reportTableData.filter((x) => x.checked == true && x['Client ID']).map((item) => {
      if (item['BOL Signed/Printed'] == 'No') {
        signRequired = true
      }
      return item['Warehouse'] + '~~' + item['Client ID'] + '~~' + item['Warehouse Order#'] + '~~' + item['BOL Signed/Printed']
    })


    if (signRequired) {
      let isInstalled = document.documentElement.getAttribute('SigPlusExtLiteExtension-installed');
      if (!isInstalled) {
        // this.commonService.notification(this.validationMessage.toasterClass.error, 'SigPlusExtLite extension is either not installed or disabled. Please install or enable extension.')

        this.confirmDialogService.confirmThis(`No Signature Pad Detected, Signature pad not connected. Proceed to print without a digital signature? Note: Manual signing may be needed later.`, () => {
          this.printTicket(); // console.log("When device is not installed and connected");
        }, () => {
          return;
        },
          'bgRed'
        );
      } else {
        var message = { "firstName": "", "lastName": "", "eMail": "", "location": "", "imageFormat": 1, "imageX": 500, "imageY": 100, "imageTransparency": false, "imageScaling": false, "maxUpScalePercent": 0.0, "rawDataFormat": "ENC", "minSigPoints": 25 };
        top.document.addEventListener('SignResponse', this.SignResponse.bind(this), { once: true });
        var messageData = JSON.stringify(message);
        var element = document.createElement("MyExtensionDataElement");
        element.setAttribute("messageAttribute", messageData);
        document.documentElement.appendChild(element);
        var evt = document.createEvent("Events");
        evt.initEvent("SignStartEvent", true, false);
        element.dispatchEvent(evt);
      }
    } else {
      this.printTicket()
    }
  }

  SignResponse(event) {
    var str = event.target.getAttribute("msgAttribute");
    var obj = JSON.parse(str);

    if (typeof (obj) === 'string') {
      obj = JSON.parse(obj);
    } else {
      obj = JSON.parse(JSON.stringify(obj));
    }

    if (obj.errorMsg != null && obj.errorMsg != "" && obj.errorMsg != "undefined" && obj.isSigned == false) {
      this.confirmDialogService.confirmThis(`Signature Declined, No signature captured. Proceed without a signature? This may require manual signing later.`, () => {
        this.printTicket(); // console.log("without signature")
      }, () => {
        return;
      },
        'bgRed'
      );
      return
    }
    else {
      if (obj.isSigned) {
        this.signatureImg = obj.imageData
        this.printTicket()
      }
    }
  }

  /*********
   * Signature code end here
   */

  //InsUpdScheduleReport
  scheduleReportNotification() {
    const currentUrl = this.router.url;
    const urlParts = currentUrl.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    if (this.form.valid) {
      let payload = {}
      Object.keys(this.form.value).forEach((k) => {
        if (k === 'SrchDateFrom' || k === 'SrchDateTo' || k === 'InventoryAsof') {
          payload[k] = (this.form.value[k] !== undefined) ? new Date(this.form.value[k]).toLocaleDateString("en-US") : '';
        } else {
          payload[k] = (typeof this.form.value[k] === 'object' && this.form.value[k] !== null)
            ? Array.isArray(this.form.value[k]) ? this.form.value[k].join() : this.form.value[k][k]
            : this.form.value[k];
        }
      })

      let filterOptions = [];
      Object.keys(payload).forEach((key) => {
        // if(key != 'dateRange'){
        let value = (payload[key] !== null) ? payload[key] : '';
        filterOptions.push(key + ':' + value)
        // }
      })

      let inputData = {
        "scheduleid": this.isEdit ? this.scheduleid : 0,
        "reportid": this.reportId,
        "username": this.userData.username,
        "params": filterOptions.join('|~'),
        "durationtype": 0,
        "schedulereportday": 0,
        "schedulereporttime": "",
        "scheduleemails": "",
        "createdby": this.userData.username,
        "lastupdatedby": this.userData.username,
        "schedulestatus": 0,
        "updatereportsearchparams": this.isEdit ? 1 : 0
      }
      this.spinner.show('FormLoader')
      this.apiserviceService.post(APIConstant.InsUpdScheduleReport, inputData, {}).subscribe((response) => {
        if (response.result) {
          const notificationMessage = this.isEdit ? 'Schedule updated successfully' : 'Schedule saved successfully';
          this.commonService.notification(this.validationMessage.toasterClass.success, notificationMessage)
          if (this.isEdit) {
            this.router.navigate(['/alerts-notifications/schedule-task', lastPart])
          }
          this.spinner.hide('FormLoader')
        }
      }, error => {
        this.spinner.hide('FormLoader')
        let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
        this.commonService.notification(this.validationMessage.toasterClass.error, errMsg)
      })
    }
  }

  /*********************************** Start Print Master BOL  *******************************/
  printMasterBOL() {
    let signRequired = false
    const printableData = this.reportTableData.filter((x) => x.checked == true && x['Client ID']).map((item) => {
      if (item['BOL Signed/Printed'] == 'No') {
        signRequired = true
      }
      return item['Warehouse'] + '~' + item['Trailer Number']
    })
    // if(signRequired && this.signatureImg==''){
    //   this.commonService.notification(this.validationMessage.toasterClass.info, 'Driver signature not captured yet, Please try agian.')
    //   return false  
    // }

    if (!printableData.length) {
      this.commonService.notification(this.validationMessage.toasterClass.info, 'You have to select atleast one item to print.')
      return false
    }

    let payload = {
      'bolPrintCopy': this.form.value.BOLPrintCopy.join(','),
      'outboundOrdersListID': printableData.join(','),
      'signatureImage': this.signatureImg ? this.signatureImg : null,
      'userName': this.userData.username,
      'driverName': this.drivername
    }

    this.spinner.show('FormLoader')
    this.apiserviceService.post(APIConstant.GetMasterBOL, payload, {}).subscribe((response) => {
      if (response.result) {
        this.signatureImg = ''
        let pdfs = response.data.pdfPath ? response.data.pdfPath.split(',') : []
        pdfs?.forEach(i => {
          window.open(i)
        })
      }
      this.spinner.hide('FormLoader')
    }, error => {
      this.spinner.hide('FormLoader')
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong';
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg)
    })
  }

  /***MANAGE SIGNATURE***/
  StartSignPrintMasterBOL() {
    if (!this.drivername && this.drivernamerequired) {
      this.reflectError = true
      return false
    }

    let signRequired = false
    const printableData = this.reportTableData.filter((x) => x.checked == true && x['Client ID']).map((item) => {
      if (item['BOL Signed/Printed'] == 'No') {
        signRequired = true
      }
      return item['Warehouse'] + '~~' + item['Trailer Number'] + '~~' + item['BOL Signed/Printed']
    })


    if (signRequired) {
      let isInstalled = document.documentElement.getAttribute('SigPlusExtLiteExtension-installed');
      if (!isInstalled) {
        // this.commonService.notification(this.validationMessage.toasterClass.error, 'SigPlusExtLite extension is either not installed or disabled. Please install or enable extension.')

        this.confirmDialogService.confirmThis(`No Signature Pad Detected, Signature pad not connected. Proceed to print without a digital signature? Note: Manual signing may be needed later.`, () => {
          this.printMasterBOL(); // console.log("When device is not installed and connected");
        }, () => {
          return;
        },
          'bgRed'
        );
      } else {
        var message = { "firstName": "", "lastName": "", "eMail": "", "location": "", "imageFormat": 1, "imageX": 500, "imageY": 100, "imageTransparency": false, "imageScaling": false, "maxUpScalePercent": 0.0, "rawDataFormat": "ENC", "minSigPoints": 25 };
        top.document.addEventListener('SignResponse', this.SignResponsePrintMasterBOL.bind(this), { once: true });
        var messageData = JSON.stringify(message);
        var element = document.createElement("MyExtensionDataElement");
        element.setAttribute("messageAttribute", messageData);
        document.documentElement.appendChild(element);
        var evt = document.createEvent("Events");
        evt.initEvent("SignStartEvent", true, false);
        element.dispatchEvent(evt);
      }
    } else {
      this.printMasterBOL()
    }
  }

  SignResponsePrintMasterBOL(event) {
    var str = event.target.getAttribute("msgAttribute");
    var obj = JSON.parse(str);

    if (typeof (obj) === 'string') {
      obj = JSON.parse(obj);
    } else {
      obj = JSON.parse(JSON.stringify(obj));
    }

    if (obj.errorMsg != null && obj.errorMsg != "" && obj.errorMsg != "undefined" && obj.isSigned == false) {
      this.confirmDialogService.confirmThis(`Signature Declined, No signature captured. Proceed without a signature? This may require manual signing later.`, () => {
        this.printMasterBOL(); // console.log("without signature")
      }, () => {
        return;
      },
        'bgRed'
      );
      return
    } else {
      if (obj.isSigned) {
        this.signatureImg = obj.imageData
        this.printMasterBOL()
      }
    }
  }

  /*********************************** End Print Master BOL  *******************************/
}
import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router,NavigationEnd } from '@angular/router';
import { validationMessage } from 'src/app/constants/validationMessage';
declare var $: any;

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  userData:any;
  sideMenu:any;
  showSideMenu:boolean=false;
  headerOpen:boolean=false
  headerCompleted:boolean=false
  noHeader:boolean=false
  headerOpenList:any[]=[]
  headerCompletedList:any[]=[]
  noHeaderList:any[]=[]
  slug:string='';
  @Output() onChange = new EventEmitter<any>();
  validationMessage = validationMessage;
 

  constructor( public router: Router,
    public titleService:Title) {
    router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {       
        let currentUrl=router.url;
        
        let currentUrlArr=currentUrl.split('/');
        let mainpath=currentUrlArr[1];  
         
        this.slug = currentUrl.slice(1); 
        
        this.userData = JSON.parse(localStorage.getItem('ArcUser'));
       
        
        
        if(this.userData && this.userData.menu){
          let ress = this.userData.menu.filter((menuItem) => { 
            return (menuItem.menuURL.includes(mainpath) && menuItem.reportMenu != null ) ? true : false;
          });
          
          if(ress.length > 0){
            this.showSideMenu = true;
            this.sideMenu = ress; 
            console.log(this.sideMenu)
            // this.sideMenu.forEach((data)=>{
            //   data.reportMenu.forEach((info)=>{
            //     if(info.reportURL=='receipts/receipt-summary-detail-report' 
            //     || info.reportURL=='receipts/receipt-reportwith-date-day-information'
            //     ||  info.reportURL=='receipts/inbound-documents'){
            //       this.headerCompleted=true
            //       this.headerCompletedList.push(info.reporttitlename)
            //     }
            //     else if(info.reportURL=='receipts/receipt-summary-detail-report'){
            //       this.headerOpen=true
            //       this.headerOpenList.push(info.reporttitlename)
            //     }
            //     else{
            //       this.noHeader=true
            //     }
            //   })
            // })
            let title = ress[0].reportMenu.filter((item)=>item.reportURL == this.slug).map(i => i.reporttitlename)
            if(title.length>0){ 
            this.onChange.emit(title[0])
            this.titleService.setTitle(validationMessage.common.heading+ title)}
          }
          else{
           
            this.showSideMenu = false;
            this.sideMenu = [];
            
          }  

        } 
      }    
    }); 
  }

  groupedReportMenu() {
    const grouped = {
        completedTransactions: [],
        openTransactions: [],
        completedTransactionsShipmentInfo: [],
        openTransactionsShipmentInfo:[]
    };

    this.sideMenu[0].reportMenu.forEach(menuItem => {
        if (
            menuItem.reportURL === 'receipts/receipt-summary-detail-report' ||
            menuItem.reportURL === 'receipts/inbound-documents' ||
            menuItem.reportURL === 'receipts/receipt-reportwith-date-day-information'
        ) {
            grouped.completedTransactions.push(menuItem);
        } else if (
            menuItem.reportURL === 'receipts/open-receipt-report' ||
            menuItem.reportURL === 'receipts/print-warehouse-receipt'
        ) {
            grouped.openTransactions.push(menuItem);
        }
        else if(
          menuItem.reportURL === 'shipment-info/order-summary-detail-report' ||
            menuItem.reportURL === 'shipment-info/outbound-documents' ||
            menuItem.reportURL === 'shipment-info/shipped-orders-date-day-information' ||
            menuItem.reportURL === 'shipment-info/print-delivery-ticket-new-version' || 
            menuItem.reportURL === 'shipment-info/Print-Packing-List' || 
            menuItem.reportURL === 'shipment-info/print-master-BOL'
        ){
           grouped.completedTransactionsShipmentInfo.push(menuItem)
        }
        else if(
          menuItem.reportURL === 'shipment-info/order-report-shorts' ||
            menuItem.reportURL === 'shipment-info/open-orders-report' 
        ){
          grouped.openTransactionsShipmentInfo.push(menuItem)
        }
    });

    return grouped;
}



  
  ngOnInit(): void {  
  }

  toggleClassOnBody(){
    $("body").toggleClass("sidebar-icon-only");   
   
  }


}

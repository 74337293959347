import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'dynamic-form-builder',
  template:`
    <form [formGroup]="formGroup" class="form-horizontal searchTemplateForm ">
    <div class="row">
    <div class="col-md-12 d-flex" style="flex-wrap: wrap;">
      <div *ngFor="let field of fields" class="searchTemplateFormFields col-md-4">
          <field-builder  (onChange)="callSomeFunction($event)"  [field]="field" [form]="formGroup"></field-builder>
      </div> 
      </div>
      </div>
    </form>
  `,
})
export class DynamicFormBuilderComponent implements OnInit {
  @Input() fields: any[] = [];
  @Input() formGroup: FormGroup;
  @Output() onChange = new EventEmitter<any>();

  constructor(public router: Router) {  }
  ngOnInit(){  }

  callSomeFunction($event){ 
    
    //For Include of "Show All Inventory Identifiers Including Case ID" in the dropdwon of Inventory Rollup Type
    //The mandatory of some more fields 

    if(this.router.url=='/receipts/receipt-summary-detail-report'){
      if(this.formGroup.controls['InventoryRollupType'].value=='AllInvtCaseID'){
        this.fields.forEach((eachField)=>{
          if(eachField.filterCode=="MasterReceiptNumber"){
            eachField.isRequired=true  
            this.setControlValidators(eachField, true);
          }
        }) 
      }else{
        this.fields.forEach((eachField)=>{
          if(eachField.filterCode=="MasterReceiptNumber"){
            eachField.isRequired=false
            this.setControlValidators(eachField, false);
          }
        }) 
  
      }
    }
    if(this.router.url=='/shipment-info/order-summary-detail-report'){
      if(this.formGroup.controls['InventoryRollupType'].value=='AllInvtCaseID'){
        this.fields.forEach((eachField)=>{
          if(eachField.filterCode=="OrdNum"){
            eachField.isRequired=true  
            this.setControlValidators(eachField, true);
          }
        }) 
      }else{
        this.fields.forEach((eachField)=>{
          if(eachField.filterCode=="OrdNum"){
            eachField.isRequired=false
            this.setControlValidators(eachField, false);
          }
        }) 
  
      }
    }

    if(this.router.url=='/inventory/inventory-summary-detail-report'){
      if(this.formGroup.controls['InventoryRollupType'].value=='AllInvtCaseID'){
        this.fields.forEach((eachField)=>{
          if(eachField.filterCode=="ItemCode"){
            eachField.isRequired=true  
            this.setControlValidators(eachField, true);
          }
        }) 
      }else{
        this.fields.forEach((eachField)=>{
          if(eachField.filterCode=="ItemCode"){
            eachField.isRequired=false
            this.setControlValidators(eachField, false);
          }
        }) 
  
      }
    }

   

    
    this.onChange.emit($event);  
  }  
  
  setControlValidators(field: any, isRequired: boolean) {
    // Get the form control using field.filterCode (which should be the name of the form control)
    const control = this.formGroup.get(field.filterCode); 
  
    if (control) {
      // If the field should be required, add the Validators.required validator
      if (isRequired) {
        control.setValidators([Validators.required]);
      } else {
        // If not required, remove the Validators.required validator
        control.clearValidators();
      }
  
      // Update the validity of the form control after modifying the validators
      control.updateValueAndValidity();
    }
  }
  
}

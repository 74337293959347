import { Component, EventEmitter, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'dropdown',
  template: `<ng-select #ngSelectOptions (change)="handleInputChange($event,true)" (search)="onSearch($event)" [labelForId]="field.filterLabel" [clearable]="true" [multiple]="field.allowMultipleSelection" [defaultValue]="field.defaultSelectedValue" [items]="field.options" bindValue="dropDownValue" bindLabel="dropDownText" placeholder="Select" 
    [formControl]="form" [closeOnSelect]="!field.allowMultipleSelection">
    
    <ng-template ng-header-tmp *ngIf="field.allowMultipleSelection && field.options != null && field.options.length>1">

    <div>
      <button class="btn btn-link"
              (click)="onSelectAll()">Select All</button>
      <button class="btn btn-link"
              (click)="onClearAll()">Clear All</button>
    </div>

  </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="field.allowMultipleSelection">
    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.dropDownText}}
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="field.allowMultipleSelection">
    <div *ngIf="items.length==1" class="ng-values-list">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
        <span class="ng-value-label">{{item.dropDownText}}</span>
        <span class="ng-value-icon right" (click)="clear(item)">×</span>
      </div>
    </div>
    <div *ngIf="items.length > 1" class="ng-summary-list">
      <div class="ng-value">
        <span class="ng-value-label">{{ items.length }} Selected</span>
      </div>
    </div>
  </ng-template>

    </ng-select>`
})
export class DropDownComponent {
  @Input() field: any = {};
  @Input() form: FormControl;
  @Output() onChange = new EventEmitter<any>();
  filteredOptions:any

  onChangeValue : boolean = false;
  selectedCounter: number = 0;
  usingArrayFrom:any;
  searchTxt:any
 
  @ViewChild('ngSelectOptions') ngSelectOptions: NgSelectComponent
  
  constructor(public router: Router) { }
  ngOnInit() {
    this.selectedCounter = this.form.value && this.form.value.length ? this.form.value.length : 0
    
    //Muitiple Dropdown Selection
    if (Array.isArray(this.form.value)) {
      const selectedObj = this.field && this.field.options ? this.field.options.filter(item => this.form.value.includes(item.dropDownValue)) : false;
      // console.log(selectedObj);
      if (selectedObj) {
        this.handleInputChange(selectedObj, this.onChangeValue);
      } else {
        this.handleInputChange(null, this.onChangeValue);
      }
    }
    //Single Dropdown Selection(Then form fields data is taken as string)
    else if (this.form.value){
      
      const selectedObj = this.field && this.field.options ? this.field.options.filter(item => this.form.value.includes(item.dropDownValue)) : false;
      // console.log(selectedObj);
      if (selectedObj) {
        this.handleInputChange(selectedObj, this.onChangeValue);
      } else {
        this.handleInputChange(null, this.onChangeValue);
      }
    }
     
  }

  //Will be used when dropdown value is changed and once at the time of initialisation
  handleInputChange($event, onChangeValue) {
   
    //handle the cross button

    console.log(this.ngSelectOptions)
    if(this.ngSelectOptions?.searchTerm==null){
      this.filteredOptions=null
    }
    
    if($event==undefined){
       $event=[];
     } 
    // //handle if user didn't select any value
    else if($event == null || $event.target !== undefined) {
      return;
    }
    

    
    //When value of change in dropdown i.e. event value is taken as object at the time of single dropdown selection.So converting this object into into array only
    let arr;
    if(typeof $event === 'object' && $event !== null && !Array.isArray($event)){
      arr=[$event];
      $event=arr;
      this.selectedCounter = $event.length;
    if (this.field.filterTypeBind) {
      this.onChange.emit({ value: $event, filterTypeBind: this.field.filterTypeBind, apiName: this.field.apiName, apiParameterName: this.field.apiParameterName, onchangevalue: onChangeValue });
    } else {
      this.onChange.emit(null);
    }

    }
    //When value of change in dropdown i.e. event value is taken as array.
    else{
    this.selectedCounter = $event.length;
    if (this.field.filterTypeBind) {
      this.onChange.emit({ value: $event, filterTypeBind: this.field.filterTypeBind, apiName: this.field.apiName, apiParameterName: this.field.apiParameterName, onchangevalue: onChangeValue });
    } else {
      this.onChange.emit(null);
    }}
  }

  public onSelectAll() {
    let selectedObj:any
    let selected:any

    if(this.router.url == "/receipts/receipt-summary-detail-report"){
      if(this.ngSelectOptions?.searchTerm==null){
        this.filteredOptions=null
      }

      if(!this.filteredOptions){     
        selected = this.field.options.map(item => item.dropDownValue);
        selectedObj = this.field.options.filter(item => item.dropDownValue);
      }
      else{
        selectedObj = this.filteredOptions
        selected = this.filteredOptions.map(item => item.dropDownValue);
      }

    }
    else{
      selected = this.field.options.map(item => item.dropDownValue);
      selectedObj = this.field.options.filter(item => item.dropDownValue);
    }

    
    this.form.patchValue(selected);
    this.handleInputChange(selectedObj, this.onChangeValue)
  }

  public onClearAll() {
    this.form.patchValue([]);
    this.handleInputChange([], true);
    
    this.searchTxt=null
    console.log(this.filteredOptions)

    
  }
  public onSearch(event: any) {
    this.searchTxt=event.term
    this.filteredOptions = this.field.options.filter(option =>
      option.dropDownText.toLowerCase().includes(this.searchTxt) || 
      option.dropDownValue.toString().toLowerCase().includes(this.searchTxt)
      
    );
}

}
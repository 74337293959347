// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//DEV ENV
/*
export const environment = {
  production: false, 
  frontendUrl: 'http://10.161.224.10:81/',
  backendUrl: 'http://10.161.224.10:1234/', 
  sessionExpireInMinutes: 45,
  sessionExpireMessage: "Session Expired: Due to 45 minutes of inactivity, you've been logged out. Please log in again to continue.",
  signalrPath: 'http://10.161.224.10:1234/signalr',
  httpRequestTimeout: 6000000
}; 
*/

//UAT ENV
 
export const environment = {
  production: false, 
  frontendUrl: 'https://testportal.arcadiaone.com/',
  backendUrl: 'https://uatapi.arcadiaone.com/', 
  sessionExpireInMinutes: 45,
  sessionExpireMessage: "Session Expired: Due to 45 minutes of inactivity, you've been logged out. Please log in again to continue.",
  signalrPath: 'https://uatapi.arcadiaone.com//signalr',
  httpRequestTimeout: 6000000
};  
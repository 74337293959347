import { Directive, HostListener, Input } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { OWL_DATE_TIME_FORMATS, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from '@danielmoncada/angular-datetime-picker';
import { regExConstant } from 'src/app/constants/regExConstant';

@Directive({
  selector: '[alphabetsOnly]'
})
export class AlphabetsOnlyDirective {
  //alphabets allowed in text box
  
  @Input() isAlphaNumeric: boolean;

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(regExConstant.alphabetsOnly).test(event.key);
  }
  constructor() { }

}

// const  MY_MOMENT_FORMATS = {
//   parse: {
//     dateInput: 'LL',
//   },
//   display: {
//     dateInput: 'LL',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };
export const MY_MOMENT_FORMATS = {
  parseInput: 'MM/DD/YYYY hh:mm A', // 12-hour format with hour and minutes only, AM/PM
  fullPickerInput: 'MM/DD/YYYY hh:mm A', // 12-hour format with hour and minutes only, AM/PM
  datePickerInput: 'MM/DD/YYYY', // No change needed here
  timePickerInput: 'hh:mm A', // 12-hour format for time, no seconds
  monthYearLabel: 'MMM YYYY', // No change needed here
  dateA11yLabel: 'LL', // No change needed here
  monthYearA11yLabel: 'MMMM YYYY', // No change needed here
};


@Directive({
  selector: '[dateFormat1]',
  providers: [
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },

  ],
})
export class CustomDateFormat1 {
}

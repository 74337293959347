<div class="fullsize-area login_blk" style='background: url("{{bodyBgImg}}") 100% center / cover no-repeat; background-position:100%; background-size:cover;'>
    <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
        <div class="login-box">
            <div class="fullsize-area">
                <div [ngClass]="uatInstance ? 'login-box-header uatBg' :  'login-box-header devBg'">
                    <img src="assets/images/ArcadiaOneLogo.svg" class="login-logo1" />
                 </div>
                <div class="login">
                    <mat-card-header>
                        <mat-card-title>Login</mat-card-title>
                    </mat-card-header>
                    <form class="example-form" [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()" autocomplete="off" *ngIf="showForm">
                        <mat-card-content> 
                            <mat-form-field appearance="outline" class="example-full-width login_icon">
                                <mat-label style="margin-left:40px; font-weight:400;">Username</mat-label>
                                 <input type="text" class="login-textbox" style="padding-left:40px" autocomplete="off"  matInput placeholder="" id="login-userid" formControlName="username" maxlength="45" required autofocus />
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="example-full-width password_icon">
                                <mat-label style="margin-left:40px; font-weight:400;">Password</mat-label>
                                <input style="padding-left:40px" matInput type="password" class="login-textbox" autocomplete="off"  matInput placeholder="" id="login-password" formControlName="password" maxlength="30" required />
                            </mat-form-field>
                        </mat-card-content>
                        <button mat-raised-button size="medium" color="primary" class="submit-button mb-3" [disabled]="btnSpinner"><i class="fa fa-sign-in fa-sm fa-sign-in-alt" *ngIf="!btnSpinner"></i><i class="fa fa-spinner fa-spin" *ngIf="btnSpinner"></i> LOGIN </button>
                        <a routerLink="" (click)="openForgetPasswordModal(forgetPasswordModal)">Forgot Password?</a>
                    </form>
                </div>
                <div class="login-note"><strong>Note</strong>: Password is case sensitive</div>
            </div>
        </div>

        <div class="fotBlk">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"> 
                <img src="assets/images/ArcadiaOneLogoWhite.svg" class="loginLogoFot" />
            </div>
           <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <ul class="terms-privacy">
                    <li><a target="_blank" href="{{environment.frontendUrl}}learning/terms-of-use.html">Terms of use</a></li> 
                    <li><a target="_blank" href="{{environment.frontendUrl}}learning/privacy-policy.html">|  &nbsp;Privacy policy</a><li>
                </ul>
           </div>
         </div>
        
    </div> 
    
    <!-- Reset Password Modal -->
    <ng-template #ResetPasswordFormModal let-modal>
        <div class="example-form"> 
            <div class="modal-header pt-0 pb-2 pl-0 pr-0" style="border-bottom:0">
                <h5 class="modal-title">Reset Password</h5>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <mat-card-content> 
                <form class="forget-form example-full-width" [formGroup]="resetPasswordForm" autocomplete="off">
                    <mat-form-field appearance="outline" class="example-full-width password_icon">
                        <mat-label style="margin-left:40px; font-weight:400;">New Password</mat-label>
                        <input type="password" style="padding-left:40px" matInput class="login-textbox"  placeholder="Enter New Password" formControlName="newpassword" maxlength="30" minlength="12"
                        [ngClass]="{ 'is-invalid': (isResetPasswordSubmitted || resetPasswordControl.newpassword.dirty) && resetPasswordControl.newpassword.errors }" autocomplete="off" required>

                        <mat-error
                            *ngIf="(isResetPasswordSubmitted || resetPasswordControl.newpassword.dirty) && resetPasswordControl.newpassword.errors"
                            class="error">
                            <span *ngIf="resetPasswordControl.newpassword.errors.required">{{
                            validationMessage.common.requireError }}</span>
                        </mat-error>

                    </mat-form-field> 
                    <mat-form-field appearance="outline" class="example-full-width password_icon">
                        <mat-label style="margin-left:40px; font-weight:400;">Confirm Password</mat-label>
                        <input type="password" style="padding-left:40px" matInput  class="login-textbox"  placeholder="Enter Confirm Password" formControlName="confirmpassword" maxlength="30" minlength="12"
                        [ngClass]="{ 'is-invalid': (isResetPasswordSubmitted || resetPasswordControl.confirmpassword.dirty) && resetPasswordControl.confirmpassword.errors }" autocomplete="off" required>
                        <mat-error
                            *ngIf="(isResetPasswordSubmitted || resetPasswordControl.confirmpassword.dirty) && resetPasswordControl.confirmpassword.errors"
                            class="error">
                            <span *ngIf="resetPasswordControl.confirmpassword.errors.required">{{
                                validationMessage.common.requireError }}</span>
                            <span *ngIf="resetPasswordControl.confirmpassword.errors.mustMatch">{{
                                validationMessage.login.passwordmatchErr }}</span>
                        </mat-error>
                    </mat-form-field> 
                    <button mat-raised-button size="medium" color="primary" class="submit-button mb-4" (click)="onResetPasswordSubmit()">Reset Password</button>
                </form> 
            </mat-card-content>            
            
        </div>  
        <div class="login-note1">
            <p class="text-left"><strong>Password Policy</strong></p> 
            <ul>
                <li><b>Length: </b>Use a password with 12 to 30 characters.</li>
                <li><b>Complexity: </b>Combine uppercase and lowercase letters, numbers, and special symbols like $, @, !, *, &, #.</li>
                <li><b>Password Reuse: </b>Avoid reusing any of your last five passwords.</li>
                <li><b>Prohibited Passwords:</b> Avoid common passwords like "Arcadiaone1!", "Password111!", and similar.</li>
            </ul>
        </div>
    </ng-template>

    <!-- Forget Password Modal -->
    <ng-template #forgetPasswordModal let-modal> 
            <div class="example-form"> 
            <div class="modal-header pt-0 pb-2 pl-0 pr-0" style="border-bottom:0">
                <h5 class="modal-title">Forgot Password ?</h5>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <mat-card-content> 
                <form class="forget-form example-full-width" [formGroup]="forgetPassword" autocomplete="off">
                    <mat-form-field appearance="outline" class="example-full-width e_mail_icon">
                        <mat-label style="margin-left:40px; font-weight:400;">Enter Registered Email</mat-label>
                        <input style="padding-left:40px" class="login-textbox" matInput type="text" placeholder="Enter Registered Email" [ngClass]="{ 'is-invalid': (isForgetPasswordSubmitted || forgetPasswordControl.email.dirty) && forgetPasswordControl.email.errors }" formControlName="email" maxlength="45" required>
                        <mat-error *ngIf="(isForgetPasswordSubmitted || forgetPasswordControl.email.dirty) && forgetPasswordControl.email.errors" class="error invalid-feedback"></mat-error>
                        <mat-error *ngIf="forgetPasswordControl.email.errors?.required">{{ validationMessage.common.requireError }}</mat-error>
                        <mat-error *ngIf="forgetPasswordControl.email.errors?.pattern">{{ validationMessage.common.emailError }}</mat-error>
                    </mat-form-field> 
                </form> 
        </mat-card-content>            
                <button mat-raised-button size="medium" color="primary" class="submit-button mb-4" (click)="onForgetPasswordSubmit()" [disabled]="btnPasswordSpinner"><i class="fa fa-spinner fa-spin" *ngIf="btnPasswordSpinner"></i><i class="fa fa-unlock fa-lg fa-fw" *ngIf="!btnPasswordSpinner"></i>Send Link</button>
        </div> 
    </ng-template> 

    <!-- Term and conditions modal -->
    <ng-template #TermsAndConditionsModal let-modal>
        <div class="modal-header" style="padding-bottom:0; border:0">
            <h4 class="modal-title"><i class="fa fa-lg fa-fw fa-lock"></i>Terms and Conditions</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        </div>
        <div class="modal-body">
            <div style="overflow-y: hidden; height: calc(100vh - 15rem);">
                <div class="px-2" style="overflow-y: auto; height: 100%;">
                    <div class="ter_con_popup">
                        <ngx-spinner name="dataLoader" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>
                        <div [innerHTML]="termsAndConditions"></div> 
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer" style="border:0; padding-top:0">
            <button class="btn btn-primary" (click)="acceptTermsAndConditions()" [disabled]="btnPasswordSpinner"><i class="fa fa-spinner fa-spin" *ngIf="btnPasswordSpinner"></i><i class="fa fa-check fa-lg fa-fw" *ngIf="!btnPasswordSpinner"></i>Accept</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('Save click')"><i class="fa fa-trash fa-lg fa-fw" style="color:#fff;"></i> Reject</button>
        </div>
    </ng-template> 
 
    <!-- Multiple Accounts selection popup -->
    <ng-template #MultipleAccountSelectionModal let-modal>
        <div class="modal-header pb-0" style="border-bottom:0;">
            <h5 class="modal-title">Please Select Account(s)</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        </div>
        <div class="modal-body mt-0">
            <p *ngIf="noAccountSelectedFlag" class="error">Please select atleast one account</p>
            <table class="table m-0">
                <thead>
                    <tr>
                        <th class="px-0" style="border-bottom:0; border-top:0;">Warehouse</th>
                        <th class="px-0" style="border-bottom:0;  border-top:0;">Account</th>
                        <th class="px-0" style="border-bottom:0;  border-top:0;">Customer</th>
                        <th class="px-0" style="border-bottom:0;  border-top:0; text-align:right;"><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let acc of tempLoginRes.data.userloginAccount; let i = index">
                        <td class="px-0">{{acc.wh_name}}</td>
                        <td class="px-0">{{acc.cust_id}}</td>
                        <td class="px-0">{{acc.cust_name}}</td>
                        <td class="px-0" style="text-align:right;"><input type="checkbox" value="{{acc.cust_id}}" [(ngModel)]="tempLoginRes.data.userloginAccount[i].checked" (change)="checkAndSetFlags()"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer pt-0" style="border-top:0;">
            <button class="submit-button widthAuto" (click)="accountSelectionSubmit()" [disabled]="btnAccountSelectionDisabled">Go To Menu</button>
         </div>
    </ng-template>
</div>
<!-- Header -->
<div class="header">
    <div [ngClass]="uatInstance ? 'topBar uatBg' :  'topBar devBg'">
        <div class="headerLogoBlk">
            <div class="header-logos">
               <a href="https://arcadiacold.com/" target="_blank">
                <img src="assets/images/arcadia-logo.png" class="header-logo3"/>
                </a>
            </div>
        </div>

        <div class="loginUserBlk">
            <div class="loginUserInner">
                <div class="login-user"><strong>Welcome </strong>{{userData.userCompanyInfo[0].displayName}}</div>
                <div class="selectedAccounts" *ngIf="selectedAccountsDisplay.length">
                    <ul>
                        <li class="selectedTxt">Selected Accounts:</li>
                       
                        <li class="no-border" *ngFor="let selectedAcc of selectedAccountsDisplay; let i=index">
                               <span *ngIf="!isSameWarehouselist">{{selectedAcc}}</span> 
                            </li> 
                            
                           
                        
                        <li *ngIf="selectedAccountCount>2" class="popupTxt">
                            <span title="{{selectedAccountsString}}" style="color: '#00a1e'; cursor: pointer">...</span></li>
                        
                        <li class="no-border" *ngIf="isSameWarehouselist">{{selectedAccountsDisplay}}@{{sameWarehouseName}}
                            <span title="{{selectedAccountsString}}" style="color: '#00a1e'; cursor: pointer">...</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="loginUserImage" *ngIf="userData.userCompanyInfo[0].companyLogo">
                <div class="UserImageBlk" style="background-image: url('{{userData.userCompanyInfo[0].companyLogo}}');">
                </div>
            </div>
        </div>

        <div class="helpLogoutBlk">
            
            <div class="helpBlock">
                <a [routerLink]="['/alerts-notifications/notification-list']" >
                    <i class="fa-bell fas" >
                        <div class="position-relative">
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" *ngIf="countNotification>0">
                                {{countNotification}}  
                              </span>
                        </div>                       
                    </i>
                </a>
            </div>
            <div class="helpBlock ml-3">
                <a [routerLink]="['learning-center']" target="_blank">
                    <i class="fas fa-question-circle"></i>
                </a>
            </div>
            <div [ngClass]="uatInstance ? 'logOutBlk uatBg' :  'logOutBlk devBg'">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                    <i class="fas fa-user-alt"></i>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openAccountSelectionPopup(MultipleAccountSelectionModal)">
                        <i class="fas fa-users"></i>
                        <span>Selected Accounts</span>
                    </button>
                    <button mat-menu-item routerLink="administration/show-user-profile">
                        <i class="fas fa-user-circle"></i>
                        <span>Profile</span>
                    </button>
                    <button mat-menu-item routerLink="user/change-password">
                        <i class="fas fa-unlock-alt"></i>
                        <span>Change Password</span>
                    </button>
                    <button mat-menu-item [routerLink]="" (click)="logout()">
                        <i class="fas fa-sign-out-alt"></i>
                        <span>Logout</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>

    <!-- For Mobile use -->  

    <div class="top-navigation" id="top-navigation">
        <span *ngFor="let menuItem of userData.menu; let i = index">
            <a [ngClass]="{'active-top-menu': menuItem.menuURL === mainMenuSlug }" routerLink="{{menuItem.menuURL}}">
                <i class="{{ menuItem.menuSectionLogo }}"></i> {{ menuItem.menuSectionName }}
            </a>
        </span> 
    </div>
</div>
<!-- Header -->

<!-- Mobile Menu Starts -->
<div class="mobile-menu">Menu</div>
<ul class="mobile_menu">
    <li *ngFor="let menuItem of userData.menu; let i = index">
        <a *ngIf="menuItem.menuSectionSubMenu == false" href="{{menuItem.menuURL}}"><i
                class="{{menuItem.menuSectionLogo}}"></i> {{ menuItem.menuSectionName }}</a>
        <a *ngIf="menuItem.menuSectionSubMenu == true"
            (click)="addActiveClass(menuItem.menuSectionName)" href="javascript:void(0)"><i
                class="{{menuItem.menuSectionLogo}}"></i> {{ menuItem.menuSectionName }}</a>

        <ul *ngIf="menuItem.menuSectionSubMenu" class="submenu" >
            <li *ngFor="let subMenuItem of reportmenu.reportMenu; let j = index">
                <a href="/{{subMenuItem.reportURL}}">
                    <i class="{{subMenuItem.reportImageName}}"></i> 
                    {{subMenuItem.reporttitlename }}
                </a>
            </li> 
        </ul> 
    </li> 
</ul>
<!-- Mobile Menu Ends -->


<!-- Multiple Accounts selection popup -->
<ng-template #MultipleAccountSelectionModal let-modal>
    <div class="modal-header pb-0" style="border-bottom:0;">
        <h5 class="modal-title">Please Select Account(s)</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body mt-0">
        <p *ngIf="noAccountSelectedFlag" class="error">Please select atleast one account</p>
        <table class="table m-0">
            <thead>
                <tr>
                    <th class="px-0" style="border-bottom:0; border-top:0;">Warehouse</th>
                    <th class="px-0" style="border-bottom:0;  border-top:0;">Account</th>
                    <th class="px-0" style="border-bottom:0;  border-top:0;">Customer</th>
                    <th class="px-0" style="border-bottom:0;  border-top:0; text-align:right;"><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let acc of userData.allAccounts; let i = index">
                    <td class="px-0">{{acc.wh_name}}</td>
                    <td class="px-0">{{acc.cust_id}}</td>
                    <td class="px-0">{{acc.cust_name}}</td>
                    <td class="px-0" style="text-align:right;"><input type="checkbox" value="{{acc.cust_id}}"
                            [(ngModel)]="userData.allAccounts[i].checked" (change)="checkAndSetFlags($event, userData.allAccounts[i].whse_code)"></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer pt-0" style="border-top:0;">
        <button type="button" class="white-button" (click)="modal.close('Save click')">Cancel</button>
        <button class="submit-button widthAuto" (click)="accountSelectionSubmit()">Update</button>
    </div>
</ng-template>
import { NgModule } from "@angular/core";

import { AlphabetNumericOnlyDirective } from "src/app/directives/alphabet-numeric-only.directive";
import { AlphabetsOnlyDirective, CustomDateFormat1 } from "src/app/directives/alphabets-only.directive";
import { FloatNumberOnlyDirective } from "src/app/directives/float-number-only.directive";
import { IntergersOnlyDirective } from "src/app/directives/intergers-only.directive";
import { NumbersOnlyDirective } from "src/app/directives/defaultnumber.directive";
import { SpecialCharacterDirective } from "src/app/directives/defaultcharacters.directive";
import { FocusDirective } from "src/app/directives/focus-element.directive"; 
import { signedNumbersDirective } from './signed-numbers.directive';

@NgModule({
  declarations: [
    AlphabetNumericOnlyDirective,
    AlphabetsOnlyDirective,
    FloatNumberOnlyDirective,
    IntergersOnlyDirective,
    NumbersOnlyDirective,
    SpecialCharacterDirective,
    FocusDirective, 
    signedNumbersDirective,
    CustomDateFormat1

  ],
  imports: [],
  providers: [],
  exports: [
    AlphabetNumericOnlyDirective,
    AlphabetsOnlyDirective,
    FloatNumberOnlyDirective,
    IntergersOnlyDirective,
    NumbersOnlyDirective,
    SpecialCharacterDirective,
    FocusDirective, 
    signedNumbersDirective,
    CustomDateFormat1
  ]
})
export class DirectivesModule {}
